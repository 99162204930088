import React, { useState } from "react";
import { StyleSheet, View, Pressable, Text } from "react-native";
import { Searchbar, Subheading } from "react-native-paper";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../types";

type SearchScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Search"
>;

const POPULAR_SEARCH_ITEMS = [
  "Milk",
  "Bread",
  "Eggs",
  "Butter",
  "Strawberries",
  "Chips",
];

interface Props {
  navigation: SearchScreenNavigationProp;
}

export function SearchScreen({ navigation }: Props) {
  const [searchQuery, setSearchQuery] = useState("");

  const onSubmitSearch = () => {
    navigation.navigate("SearchItemMatches", {
      searchQuery: searchQuery.trim(),
    });
    setSearchQuery("");
  };

  const handlePopularItemSearch = (popularItem: string) => {
    navigation.navigate("SearchItemMatches", {
      searchQuery: popularItem,
    });
  };

  const SearchChip = ({ content }) => {
    return (
      <Pressable
        style={({ pressed }) => [
          {
            backgroundColor: pressed ? "rgb(210, 230, 255)" : "white",
          },
          styles.chip,
        ]}
        onPress={() => {
          handlePopularItemSearch(content);
        }}
      >
        <Text
          style={{
            color: "#C4C4C4",
            fontSize: 12,
            fontFamily: "montserrat-regular",
          }}
        >
          {content}
        </Text>
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <Searchbar
        placeholder="Search"
        onChangeText={(text) => setSearchQuery(text)}
        value={searchQuery}
        onIconPress={onSubmitSearch}
        onSubmitEditing={onSubmitSearch}
      />
      <View style={styles.popularSearches}>
        <Subheading style={styles.subheading}>POPULAR SEARCHES</Subheading>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {POPULAR_SEARCH_ITEMS.map((searchItem) => (
            <SearchChip key={searchItem} content={searchItem} />
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "white",
  },
  popularSearches: { marginTop: 30 },
  subheading: {
    fontSize: 14,
    fontFamily: "montserrat-medium",
    marginBottom: 15,
  },
  chip: {
    width: "32%",
    marginBottom: 10,
    borderColor: "#C4C4C4",
    borderWidth: 0.7,
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
  },
});
