import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { Surface, IconButton } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { OfferEntry } from "../../../../../../types";

interface Props {
  deal: OfferEntry;
  onSaveOffer(deal: OfferEntry): void;
}

export function HomeItemCard({ deal, onSaveOffer }: Props) {
  const [key, offer] = deal;
  const { image_url, name, saved } = offer;
  const dealRating =
    ((offer.flyerItems.length > 0) & 1) +
    ((offer.cashbackOffers.length > 0) & 1) +
    ((offer.onlineCoupons.length > 0) & 1);

  const flames = [...Array(dealRating)].map((value, index: number) => (
    <MaterialCommunityIcons name="fire" size={18} color="#FF7236" key={index} />
  ));

  return (
    <Surface key={key} style={styles.surface}>
      <View style={styles.itemCard}>
        <View style={styles.cardContent}>
          <Image
            style={styles.itemImage}
            source={{ uri: image_url }}
            resizeMode="cover"
            height={70}
          />
          <View style={{ paddingLeft: 80 }}>
            <Text style={styles.title}>{name}</Text>
            <View style={styles.flames}>{flames}</View>
          </View>
        </View>

        <IconButton
          icon={saved ? "cart" : "cart-plus"}
          color={saved ? "#C76033" : "#D6D6D6"}
          size={20}
          onPress={() => onSaveOffer(deal)}
          accessibilityLabel={saved ? 'remove item from your list' : 'add item to your list'}
        />
      </View>
    </Surface>
  );
}

const styles = StyleSheet.create({
  surface: {
    height: 80,
    elevation: 5,
    borderRadius: 4,
    flex: 1,
    flexDirection: "column",
    marginHorizontal: 16,
  },
  itemCard: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContent: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginLeft: 16,
    fontFamily: "gotham-bold",
    marginBottom: 6,
  },
  itemImage: {
    width: 70,
    height: 70,
    marginTop: 5,
    marginLeft: 5,
    ...StyleSheet.absoluteFillObject,
  },
  flames: {
    flexDirection: "row",
    marginLeft: 16,
  },
});
