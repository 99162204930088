import { SavedOffer } from "../types";

export function buildTripOffer(item: SavedOffer) {
  return {
    ...item,
    flyerItems: item.flyerItems || [],
    cashbackOffers: item.cashbackOffers || [],
    onlineCoupons: item.onlineCoupons || [],
  };
}
