import { StatusBar } from "expo-status-bar";
import '@expo/match-media'

import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  configureFonts,
  Provider as PaperProvider,
  DefaultTheme,
} from "react-native-paper";
import { LogBox, Platform } from "react-native";
import _ from "lodash";

import useCachedResources from "./hooks/useCachedResources";
import { Navigation } from "./navigation";

const fontConfig = {
  web: {
    regular: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "gotham-bold",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
  },
  ios: {
    regular: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "gotham-bold",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
  },
  android: {
    regular: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    medium: {
      fontFamily: "gotham-bold",
      fontWeight: "normal",
    },
    light: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
    thin: {
      fontFamily: "gotham-light",
      fontWeight: "normal",
    },
  },
};

if (Platform.OS !== "web") {
  LogBox.ignoreLogs(["Setting a timer"]);
  const _console = _.clone(console);
  console.warn = (message: any) => {
    if (message.indexOf("Setting a timer") <= -1) {
      _console.warn(message);
    }
  };
}

const theme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
  colors: {
    ...DefaultTheme.colors,
    primary: '#A8CF92',
    accent: '#C76033',
    underlineColor: 'transparent'
  },
};

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <PaperProvider theme={theme}>
        <SafeAreaProvider>
          <Navigation />
          <StatusBar style="dark" />
        </SafeAreaProvider>
      </PaperProvider>
    );
  }
}
