import React from "react";
import { StyleSheet, View } from "react-native";
import { Text, Avatar } from "react-native-paper";

interface Props {
  icon: string;
  emptyStateMessage: string;
}

export function EmptyState({ icon, emptyStateMessage }: Props) {
  return (
    <View style={styles.listContent}>
      <Avatar.Icon size={40} icon={icon} style={{ marginBottom: 20 }} />
      <Text style={styles.emptyStateText}>{emptyStateMessage}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  listContent: {
    flex: 1,
    alignItems: "center",
    marginTop: "25%",
  },
  emptyStateText: {
    fontSize: 14,
    fontWeight: "700",
  },
});
