import React from "react";
import { View, Text, Image, Pressable, Linking } from "react-native";
import { EvilIcons } from "@expo/vector-icons";

import instructions from './copy/en.json';

enum OS {
  Android = 'android',
  IOS = 'ios'
}

interface Props {
  os: OS;
}

export function WebInstallScreen({ os }: Props) {
  const url = "https://play.google.com/store/apps/details?id=app.savin.groceries";

  const handleOpenLink = async () => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    }
  }
  return (
    <View
      style={{
        padding: 10,
        backgroundColor: "gray",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          backgroundColor: "white",
          height: "60%",
          marginHorizontal: 15,
          flexDirection: "column",
          borderRadius: 18,
        }}
      >
        <View
          style={{
            marginBottom: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            paddingHorizontal: 35,
          }}
        >
          <Image
            style={{ width: 114, height: 114, borderRadius: 18 }}
            source={require("../../assets/images/icon.png")}
          />
          <Text
            style={{
              fontFamily: "System",
              fontSize: 32,
              fontWeight: "bold",
              paddingTop: 20,
              paddingBottom: 8,
            }}
          >
            Install Savin
          </Text>
          <Text
            style={{ fontFamily: "System", fontSize: 18, textAlign: "center" }}
          >
            {os === OS.Android ? instructions.android : instructions.ios}
          </Text>
          {os === OS.Android &&
            <View style={{ alignContent: 'center', alignItems: 'center', marginTop: 20 }}>
              <Pressable onPress={handleOpenLink}>
                <Image
                  style={{ width: 214, height: 74 }}
                  source={require("../../assets/images/google-play-badge.png")}
                  resizeMode="contain"
                />
              </Pressable>
            </View>
          }
        </View>

        {os === OS.IOS &&
          <View
            style={{
              backgroundColor: "#F9F9F9",
              paddingHorizontal: 5,
              paddingVertical: 15,
              borderBottomLeftRadius: 18,
              borderBottomRightRadius: 18,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ fontFamily: "System", textAlign: "center", fontSize: 16 }}
            >
              Just tap
            </Text>
            <EvilIcons
              name="share-apple"
              size={30}
              color="#007AFF"
              style={{ marginBottom: 1 }}
            />
            <Text
              style={{ fontFamily: "System", textAlign: "center", fontSize: 16 }}
            >
              then 'Add to Home Screen'
            </Text>
          </View>
        }
      </View>
    </View >
  );
}
