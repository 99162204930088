import { mutate } from "swr";
import { firebase } from "../firebaseConfig";

export async function deleteItem(itemKey: string, tempData) {
  delete tempData[itemKey];

  mutate("getCoupons", { ...tempData, updatedItem: itemKey }, false);
  await firebase
    .functions()
    .httpsCallable("deleteItemById")({ id: itemKey })
    .then((result) => {
      console.log(result);
    });
}
