import React from "react";
import { StyleSheet } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import Constants from 'expo-constants';

import { ShoppingListParamList } from "../../../types";
import { ShoppingListScreen } from "../../../screens/ShoppingListScreen";
import { ListItemMatchesScreen } from "../../../screens/ListItemMatchesScreen";

const ShoppingListStack = createStackNavigator<ShoppingListParamList>();

export function ShoppingListNavigator() {
  return (
    <ShoppingListStack.Navigator>
      <ShoppingListStack.Screen
        name="List"
        component={ShoppingListScreen}
        options={{
          headerTitle: "Your Trip",
          headerStyle: { elevation: 0 },
          headerTitleStyle: { fontFamily: "gotham-bold", fontSize: 22 },
        }}
      />
      <ShoppingListStack.Screen
        name="ListItemMatches"
        component={ListItemMatchesScreen}
        options={() => ({
          title: null
        })}
      />
    </ShoppingListStack.Navigator>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "white",
    elevation: 0,
    textAlign: "left",
    height: 90,
  },
  headerTitle: {
    fontSize: 22,
    paddingTop: Constants.statusBarHeight,
    fontFamily: "gotham-bold",
    textAlign: "left",
  },
});
