import React, { useState } from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import { Title } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import * as WebBrowser from "expo-web-browser";

import {
  CashbackOffer,
  FlyerItem,
  OnlineCoupon,
  ItemTypes,
  ItemType,
} from "../../../../types";
import { OfferItem } from "../OfferItem";
import { MutationData } from "../../../types";

interface Props {
  listData: (CashbackOffer | FlyerItem | OnlineCoupon)[];
  onManageSaveState: ({ itemId, itemTypes, itemIndex }: MutationData) => void;
  listHeading: string;
  itemTypes: {
    listName: string;
    type: ItemType;
  };
}

const LEARN_MORE_LINK = 'https://www.savin.app/new-to-grocery-savings';

function handleOpenLink() {
  WebBrowser.openBrowserAsync(LEARN_MORE_LINK);
}

export function MatchesHorizontalList({
  listData,
  onManageSaveState,
  listHeading,
  itemTypes,
}: Props) {
  const [refresh, setRefresh] = useState(false);

  const onPressFunction = async (
    itemId: string,
    itemTypes: ItemTypes,
    itemIndex: number
  ) => {
    await onManageSaveState({ itemId, itemTypes, itemIndex });
    setRefresh(!refresh);
  };

  const icon = (itemType: ItemType) => {
    switch (itemType) {
      case ItemType.CashbackItem:
        return "wallet-outline"
      case ItemType.Coupon:
        return "content-cut"
      case ItemType.FlyerItem:
        return "newspaper-variant-outline"
    }
  }

  const content = () => {
    switch (itemTypes.type) {
      case ItemType.CashbackItem:
        return "Once you’ve purchased the item, simply take a photo and upload your receipt to the platform."
      case ItemType.Coupon:
        return "Print out the coupon and bring it to the store with you."
      case ItemType.FlyerItem:
        return "If your store allows price-matching, have the digital flyer handy."
    }
  }

  return (
    <View style={styles.container}>

      <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <MaterialCommunityIcons name={icon(itemTypes.type)} size={20} style={{ margin: 0, paddingRight: 5 }} />
        <Title style={{ fontSize: 16 }}>{listHeading}</Title>
      </View>
      <Text>{content()} <Text onPress={() => {
        handleOpenLink();
      }} style={{ textDecorationLine: "underline" }}>Learn more</Text></Text>
      <FlatList
        data={listData}
        extraData={refresh}
        renderItem={({ item, index }) => (
          <OfferItem
            offerItem={item}
            itemIndex={index}
            onPress={onPressFunction}
            itemTypes={itemTypes}
          />
        )}
        keyExtractor={(item, index) => index.toString()}
        horizontal
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  surface: {
    elevation: 5,
    margin: 7,
    borderRadius: 4,
    padding: 6,
  },
  container: {
    marginVertical: 10,
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  preHeading: {
    color: "black",
    fontSize: 14,
    letterSpacing: 1,
    marginTop: 10,
    fontFamily: "montserrat-medium",
  },
});
