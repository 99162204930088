import React from "react";
import Swiper from 'react-native-web-swiper';
import { StackNavigationProp } from "@react-navigation/stack";
import { Image, View, StyleSheet, Text, Platform, Dimensions, TouchableOpacity } from "react-native";

import { RootStackParamList } from "../../types";

type OnboardingWalkthroughProp = StackNavigationProp<
  RootStackParamList,
  "OnboardingWalkthrough"
>;

interface Props {
  navigation: OnboardingWalkthroughProp;
}

export function OnboardingWalkthrough({ navigation }: Props) {
  const handleCompleteOnboarding = () => {
    navigation.navigate("Root");
    navigation.reset({
      index: 0,
      routes: [{ name: "Root" }],
      key: null,
    });
  };

  return (
    <>
      <View style={styles.container}>
        <Swiper loop={false} controlsProps={{
          lastNextElement:
            <TouchableOpacity onPress={handleCompleteOnboarding}>
              <Text style={{ color: '#2089dc', fontSize: 16, fontWeight: '500' }}>
                {"Done"}
              </Text>
            </TouchableOpacity>,
          firstPrevElement: <TouchableOpacity onPress={handleCompleteOnboarding}>
            <Text style={{ color: '#2089dc', fontSize: 16, fontWeight: '500' }}>
              {"Skip"}
            </Text>
          </TouchableOpacity>
        }}>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/1_Home.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/2_My_List_Detail.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/3_My_List_Detail.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/4_My_List_Detail.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/5_Prototype_My_Coupons.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/6_My_Coupons.png")}
            />
          </View>
          <View style={styles.slideContainer}>
            <Image
              style={styles.img}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/7_Cashback.png")}
            />
          </View>
        </Swiper>
      </View>
    </>
  );
}


const imgHeight = Platform.OS !== 'web' ? "100%" : Dimensions.get('window').height;
const imgWidth = Platform.OS !== 'web' ? "100%" : Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slideContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
  },
  img: {
    width: imgWidth,
    height: imgHeight,
  }
});
