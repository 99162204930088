import React, { useState, useContext } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { ActivityIndicator, Snackbar, useTheme } from "react-native-paper";
import useSWR, { mutate } from "swr";
import * as Crypto from "expo-crypto";
import Moment from "moment";

import { OfferMap } from "../../types";
import { firebase } from "../../firebaseConfig";
import { fetcher, track } from "../../utilities";
import { AuthContext } from "../../AuthContext";
import { MutationData } from "../../components/types";
import { ItemMatches } from "../../components/ItemMatches";

import { MatchItemScreenRouteProp } from "./types";
import { handleOfferSaveState } from "./utilities";

interface Props {
  route: MatchItemScreenRouteProp;
}

export function HomeItemMatchesScreen({ route }: Props) {
  const user = useContext(AuthContext);
  const { colors } = useTheme();

  const { key } = route.params;
  const { data: couponsData } = useSWR("getCoupons", fetcher);
  const { data: matchesData } = useSWR<OfferMap>("getMatches", fetcher);

  const [snackVisible, setSnackVisible] = useState(false);
  const [saveState, setSaveState] = useState<boolean>();

  if (!matchesData) {
    return (
      <View style={styles.homeItemMatches}>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  const dealOffer = matchesData[key as keyof OfferMap];

  if (!dealOffer) {
    return (
      <View>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  const handleSaveItemState = async () => {
    handleOfferSaveState([key, dealOffer], couponsData, matchesData);
  };

  const handleSaveState = async ({
    itemId,
    itemTypes,
    itemIndex,
  }: MutationData) => {
    const { listName, type } = itemTypes;
    const searchId = listName + "SearchId";
    let tempMatchesData = matchesData;

    const now = Moment.utc().format();
    const itemSaved: boolean = dealOffer[listName][itemIndex].saved;

    setSaveState(itemSaved);
    setSnackVisible(false);

    if (couponsData && matchesData) {
      const digestId = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        dealOffer.name
      );

      tempMatchesData[key][listName][itemIndex].saved = !itemSaved;
      tempMatchesData[key].saved = true;

      mutate("getMatches", { ...tempMatchesData, updatedAt: now }, false);

      couponsData[digestId] = {
        ...dealOffer,
        saved: !itemSaved,
        coupon: {
          createdAt: now,
          product: dealOffer.name,
          updatedAt: now,
        },
      };

      mutate("getCoupons", { ...couponsData, updatedAt: now }, false);

      setSnackVisible(true);

      track(`${route.name}.ManageItemSaveState`, user.uid, {
        saved: !itemSaved,
        itemType: type,
      });

      var manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");
      manageItemSavedState({
        searchId: dealOffer[searchId],
        itemId,
        itemType: type,
        action: itemSaved ? "delete" : "save",
        name: dealOffer.name,
        id: digestId,
      }).then((result) => {
        console.log("result", result);
      });
    }
  };

  return (
    <View style={styles.homeItemMatches}>
      <ScrollView>
        <View style={styles.scrollContainer}>
          {dealOffer ? (
            <ItemMatches
              item={dealOffer}
              onManageSaveState={handleSaveState}
              onIconPress={handleSaveItemState}
            />
          ) : (
            <View>
              <Text>No information available about this deal</Text>
            </View>
          )}
        </View>
      </ScrollView>
      <Snackbar
        duration={2500}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
      >
        Item {saveState ? "deleted" : "saved, find it in Your Trip"}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  homeItemMatches: {
    backgroundColor: "white",
    height: "100%",
  },
  scrollContainer: {
    padding: 10,
  },
});
