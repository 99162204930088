import React from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import useSWR, { mutate } from "swr";
import * as Crypto from "expo-crypto";
import Moment from "moment";

import { firebase } from "../../firebaseConfig";
import { fetcher, searchFetcher } from "../../utilities/fetcher";
import { ItemMatches } from "../../components/ItemMatches";
import { MutationData } from "../../components/types";

import { SearchItemMatchesScreenRouteProp } from "./types";
import { SavedOfferMap, SearchData } from "../../types";

interface Props {
  route: SearchItemMatchesScreenRouteProp;
};

export function SearchItemMatchesScreen({ route }: Props) {
  const { searchQuery } = route.params;
  const { colors } = useTheme();

  const { data: couponsData } = useSWR<SavedOfferMap>("getCoupons", fetcher);
  const { data: searchData } = useSWR<SearchData>(
    ["searchItem", searchQuery],
    searchFetcher
  );

  const item = searchData?.matches[0];

  if (!item) {
    return (
      <View style={styles.searchItemMatches}>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  const handleSaveSearchItem = async () => {
    let tempSearchData = searchData;
    const tempCouponsData = couponsData;
    const digestId = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      item.name
    );

    if (tempCouponsData && tempSearchData) {
      const now = Moment.utc().format();
      tempSearchData.matches[0].saved = true;
      tempSearchData.updatedAt = now;

      mutate(["searchItem", searchQuery], tempSearchData, false);

      tempCouponsData[digestId] = {
        ...item,
        coupon: {
          createdAt: now,
          product: item.name,
          updatedAt: now,
        },
        saved: true,
      };

      mutate(
        "getCoupons",
        {
          ...tempCouponsData,
          updatedAt: now
        },
        false
      );

      let manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");

      manageItemSavedState({
        name: item.name,
        id: digestId,
      }).then((result) => {
        console.log("result", result);
      });
    }
  };

  const handleSaveState = async ({
    itemId,
    itemTypes,
    itemIndex,
  }: MutationData) => {
    const { listName, type } = itemTypes;
    const searchId = listName + "SearchId";
    let tempCouponsData = couponsData;
    let tempSearchData = searchData;
    let now = Moment.utc().format();

    const itemSaved = item[listName][itemIndex].saved;

    if (tempCouponsData && tempSearchData) {
      const digestId = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        item.name
      );

      tempSearchData.matches[0][listName][itemIndex].saved = !itemSaved;
      tempSearchData.updatedAt = now;

      mutate(["searchItem", searchQuery], tempSearchData, false);

      couponsData[digestId] = {
        ...item,
        coupon: {
          createdAt: now,
          product: item.name,
          updatedAt: now,
        },
        saved: !itemSaved,
      };

      mutate("getCoupons", { ...couponsData, updatedAt: now }, false);

      var manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");

      manageItemSavedState({
        searchId: item[searchId],
        itemId,
        itemType: type,
        action: itemSaved ? "delete" : "save",
        name: item.name,
        id: digestId,
      }).then((result) => {
        console.log("result", result);
      });
    }
  };

  return (
    <View style={styles.searchItemMatches}>
      <ScrollView>
        <View style={styles.scrollContainer}>
          {item ? (
            <ItemMatches
              item={item}
              onManageSaveState={handleSaveState}
              offerIsSearchResult
              onIconPress={() => null}
            />
          ) : (
            <View>
              <Text>No information available about this deal</Text>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  searchItemMatches: {
    backgroundColor: "white",
    height: "100%",
  },
  scrollContainer: {
    padding: 10,
  },
});
