import React from "react";
import { Pressable, Platform } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList, OfferEntry } from "../../../../types";

import { HomeItemCard } from "./components/HomeItemCard/HomeItemCard";

type HomeScreenNavigationProp = StackNavigationProp<RootStackParamList, "Root">;

interface Props {
  deal: OfferEntry;
  navigation: HomeScreenNavigationProp;
  onSaveOffer(deal: OfferEntry): void;
}

export function HomeListItem({ deal, navigation, onSaveOffer }: Props) {
  const [key] = deal;

  return (
    <Pressable
      onPress={() =>
        navigation.navigate("HomeItemMatches", {
          key,
        })
      }
      style={{ height: Platform.OS === 'web' ? 80 : "auto", marginBottom: 16 }}
    >
      <HomeItemCard deal={deal} onSaveOffer={onSaveOffer} />
    </Pressable>
  );
}
