import React, { useState } from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { Searchbar, ActivityIndicator, Text } from "react-native-paper";
import useSWR from "swr";
import { useTheme } from "react-native-paper";

import { EmptyState } from "../../components/EmptyState";
import { fetcher } from "../../utilities";

import { SavedOfferMap, SavedOfferEntry, SavedOffer } from "../../types";

import { TripOfferItem } from "./components/TripOfferItem";
import { ListScreenNavigationProp } from "./types";
import { buildSavedItemsCoupons } from "./utilities";

interface Props {
  navigation: ListScreenNavigationProp;
}

export type SearchData = SavedOffer[] | {} | undefined;

export function ShoppingListScreen({ navigation }: Props) {
  const { colors } = useTheme();
  const { data: savedOffers } = useSWR<SavedOfferMap>("getCoupons", fetcher);

  const [searchData, setSearchData] = useState<SavedOfferEntry[]>();
  const [searchQuery, setSearchQuery] = useState("");

  const savedItemsCoupons = buildSavedItemsCoupons(savedOffers);

  const onSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);

    if (searchQuery.length === 0) {
      setSearchData(savedItemsCoupons);
    }

    let filteredListItems = savedItemsCoupons.filter((entry) => {
      let [key, item] = entry;
      if (key !== "updatedAt" && key !== "updatedItem") {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
    });

    setSearchData(filteredListItems);
  };

  return (
    <View style={styles.shoppingList}>
      <View style={styles.searchBar}>
        <Searchbar
          placeholder="Search Items in Your Trip"
          onChangeText={(text) => onSearch(text)}
          value={searchQuery}
        />
      </View>
      <View style={styles.listContent}>
        {!savedOffers ? (
          <View style={{ height: "100%", justifyContent: "center" }}>
            <ActivityIndicator animating={true} color={colors.primary} />
          </View>
        ) : (
          <FlatList
            data={searchQuery && searchData ? searchData : savedItemsCoupons}
            renderItem={({ item }) => (
              <TripOfferItem savedOfferEntry={item} navigation={navigation} />
            )}
            keyExtractor={(item) => item[0]}
            extraData={savedItemsCoupons?.updatedAt}
            ListEmptyComponent={
              !searchQuery ? (
                <EmptyState
                  icon="cart-outline"
                  emptyStateMessage="Items you had from Saved will show up here!"
                />
              ) : (
                <Text>No search results</Text>
              )
            }
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  shoppingList: {
    backgroundColor: "white",
    height: "100%",
  },
  listContent: {
    flex: 1,
  },
  searchBar: {
    padding: 16,
  },
});
