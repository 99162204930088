import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import { HomeNavigator } from "./components/HomeNavigator";
import { CouponsNavigator } from "./components/CouponsNavigator";
import { ShoppingListNavigator } from "./components/ShoppingListNavigator";
import { SearchNavigator } from "./components/SearchNavigator";
import { MainList } from "../../types";

const MainTabs = createBottomTabNavigator<MainList>();

export function MainNavigator() {
  return (
    <MainTabs.Navigator
      initialRouteName="Deals"
      lazy={false}
      tabBarOptions={{
        activeBackgroundColor: "#A8CF92",
        activeTintColor: "white",
        labelStyle: {
          paddingBottom: 8,
          fontSize: 12,
          fontFamily: "montserrat-regular",
        },
        keyboardHidesTabBar: true,
      }}
    >
      <MainTabs.Screen
        name="Deals"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="fire" color={color} />,
        }}
      />
      <MainTabs.Screen
        name="My Coupons"
        component={CouponsNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="card-bulleted" color={color} />
          ),
        }}
      />
      <MainTabs.Screen
        name="Your Trip"
        component={ShoppingListNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="cart-outline" color={color} />
          ),
        }}
      />
      <MainTabs.Screen
        name="Search"
        component={SearchNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="magnify" color={color} />
          ),
        }}
      />
    </MainTabs.Navigator>
  );
}

function TabBarIcon(props: { name: string; color: string }) {
  return (
    <MaterialCommunityIcons size={22} style={{ marginBottom: -3 }} {...props} />
  );
}
