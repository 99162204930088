import React from "react";
import { View, StyleSheet, Image, Platform, Dimensions } from "react-native";
import { Button, Title, Text } from "react-native-paper";

import { AuthScreenProps } from "../../types";

export function AuthScreen({ navigation }: AuthScreenProps) {
  return (
    <View style={styles.container}>
      <Image
        style={styles.logo}
        resizeMode="contain"
        source={require("./assets/onboarding/onboarding-2.png")}
      />

      <Title style={{ fontSize: 34, paddingVertical: 5, textAlign: "center" }}>
        Find and save your favourite deals
      </Title>
      <Text style={{ fontSize: 16, marginBottom: 45, textAlign: "center" }}>
        Search for what you need and save it to make shopping easier
      </Text>
      <Button
        mode="contained"
        compact
        onPress={() => navigation.navigate("SignUp")}
        style={styles.button}
        color="#A8CF92"
        labelStyle={{ color: "white" }}
      >
        Get Started
      </Button>

      <Button
        mode="outlined"
        compact
        onPress={() => navigation.navigate("SignIn")}
        style={styles.button}
        labelStyle={{ color: "black" }}
      >
        Sign In
      </Button>
      <Button
        mode="text"
        color="black"
        onPress={() => navigation.navigate("AnonymousSignUp")}
        uppercase={false}
        labelStyle={{ fontFamily: "montserrat-regular" }}
      >
        Continue without signing up
      </Button>
    </View>
  );
}

const imageHeight = Platform.OS === "web" ? Dimensions.get('window').height / 3 : "45%"
const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  logo: {
    width: "100%",
    height: imageHeight,
    marginBottom: 30,
  },
  button: {
    width: "80%",
    marginBottom: 10,
  },
});
