import React from "react";
import { View, StyleSheet } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList, SavedOfferMap } from "../../types";
import { SavedListItemMatches } from "../../components/SavedListItemMatches";

type SavedItemMatchesScreenRouteProp = RouteProp<
  RootStackParamList,
  "SavedItemMatches"
>;

export type SavedItemMatchesNavigationProp = StackNavigationProp<
  RootStackParamList,
  "SavedItemMatches"
>;

interface Props {
  route: SavedItemMatchesScreenRouteProp;
  navigation: SavedItemMatchesNavigationProp;
};

export function SavedItemMatchesScreen({ navigation, route }: Props) {
  const { key } = route.params;

  return (
    <View style={styles.container}>
      <SavedListItemMatches itemKey={key} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "white",
  },
});
