import { mutate } from "swr";
import Moment from "moment";
import * as Crypto from "expo-crypto";

import { firebase } from "../../firebaseConfig";
import { SavedOffer, Offer } from "../../types";

import { deleteItem } from "../../utilities";

export const handleOfferSaveState = async (
  deal: [string, Offer | SavedOffer],
  savedOffers?: Record<string, SavedOffer>,
  data?: Record<string, Offer>
) => {
  const [key, offer] = deal;
  const isSaved = offer.saved;
  const now = Moment.utc().format();

  if (savedOffers && data) {
    data[key].saved = !offer.saved;

    mutate(
      "getMatches",
      {
        ...data,
        updatedAt: now,
      },
      false
    );

    const digestId = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      offer.name
    );

    if (!isSaved) {
      savedOffers[digestId] = {
        ...offer,
        coupon: {
          createdAt: now,
          product: offer.name,
          updatedAt: now,
        },
        saved: true,
      };
      savedOffers.updatedAt = now;

      mutate(
        "getCoupons",
        {
          ...savedOffers,
        },
        false
      );

      let manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");

      manageItemSavedState({
        name: offer.name,
        id: digestId,
      }).then((result) => {
        console.log("result", result);
      });
    } else {
      deleteItem(digestId, savedOffers);
    }
  }
};
