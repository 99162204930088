import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { IconButton } from "react-native-paper";

import { HomeParamList } from "../../../types";
import { HomeScreen } from "../../../screens/HomeScreen";
import { ProfileScreen } from "../../../screens/ProfileScreen";
import { HomeItemMatchesScreen } from "../../../screens/HomeItemMatchesScreen";

const HomeStack = createStackNavigator<HomeParamList>();

export function HomeNavigator() {
  const navigation = useNavigation();

  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="Deals"
        component={HomeScreen}
        options={() => ({
          headerStyle: { elevation: 0 },
          headerLeft: () => (
            <IconButton
              icon="menu"
              color="black"
              size={24}
              onPress={() => navigation.navigate("Profile")}
              accessibilityLabel='Open menu'
            />
          ),
          title: null
        })}
      />
      <HomeStack.Screen
        name="HomeItemMatches"
        component={HomeItemMatchesScreen}
        options={() => ({
          title: null
        })}
      />
      <HomeStack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ title: "Settings" }}
      />
    </HomeStack.Navigator>
  );
}
