import React from "react";
import { RouteProp } from "@react-navigation/native";

import { RootStackParamList } from "../../types";
import { SavedListItemMatches } from "../../components/SavedListItemMatches";

type ListItemMatchesScreenRouteProp = RouteProp<
  RootStackParamList,
  "ListItemMatches"
>;

interface Props {
  route: ListItemMatchesScreenRouteProp;
};

export function ListItemMatchesScreen({ route }: Props) {
  const { key } = route.params;

  return <SavedListItemMatches itemKey={key} tripItem />;
}
