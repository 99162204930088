import React from "react";
import { ScrollView, View } from "react-native";
import {
  Portal,
  Dialog,
  Paragraph,
  Subheading,
  Button,
} from "react-native-paper";

import termsAndConditions from "./TermsAndConditions.json";

interface Props {
  modalOpen: boolean;
  onClose(): void;
  onAgreeToTerms(): void;
}

export function TermsAndConditionsModal({
  modalOpen,
  onClose,
  onAgreeToTerms,
}: Props) {
  return (
    <Portal>
      <Dialog visible={modalOpen} onDismiss={onClose} style={{ height: "90%" }}>
        <Dialog.Title>Terms and Conditions</Dialog.Title>
        <Dialog.ScrollArea>
          <ScrollView>
            <View style={{ padding: 10 }}>
              <Paragraph>{termsAndConditions.p1}</Paragraph>
              <Paragraph>{termsAndConditions.p2}</Paragraph>
              <Paragraph>{termsAndConditions.p3}</Paragraph>
              <Paragraph>{termsAndConditions.p4}</Paragraph>
              <Paragraph>{termsAndConditions.p5}</Paragraph>
              <Paragraph>{termsAndConditions.l1}</Paragraph>
              <Paragraph>{termsAndConditions.l2}</Paragraph>
              <Paragraph>{termsAndConditions.l3}</Paragraph>
              <Paragraph>{termsAndConditions.l4}</Paragraph>
              <Paragraph>{termsAndConditions.p6}</Paragraph>
              <Paragraph>{termsAndConditions.p7}</Paragraph>
              <Paragraph>{termsAndConditions.p8}</Paragraph>
              <Paragraph>{termsAndConditions.p9}</Paragraph>
              <Paragraph>{termsAndConditions.p10}</Paragraph>
              <Subheading>{termsAndConditions.h21}</Subheading>
              <Paragraph>{termsAndConditions.p11}</Paragraph>
              <Paragraph>{termsAndConditions.p12}</Paragraph>
              <Subheading>{termsAndConditions.h22}</Subheading>
              <Paragraph>{termsAndConditions.p13}</Paragraph>
            </View>
          </ScrollView>
        </Dialog.ScrollArea>
        <Dialog.Actions>
          <Button onPress={() => onClose()}>Cancel</Button>
          <Button mode="contained" onPress={() => onAgreeToTerms()}>
            Agree
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
