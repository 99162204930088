import React from "react";
import { View, StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";

interface FormField {
  label: string;
  value: string;
  onChange: (item: any) => void;
  onTouch?: () => void;
  additonalContent?: JSX.Element;
}

interface Props {
  formField: FormField;
  allowMultiline?: boolean;
  showCamera(): void;
}

export function CouponFormField({
  formField,
  allowMultiline,
  showCamera,
}: Props) {
  const { label, value, onChange } = formField;

  const isDiscountField = label === "Discount";

  return (
    <View style={styles.formFieldContainer}>
      <TextInput
        label={label}
        left={isDiscountField && <TextInput.Affix text="$" />}
        value={value}
        onChangeText={(text) => onChange(text)}
        onTouchStart={formField.onTouch}
        style={styles.formInput}
        mode="outlined"
        multiline={allowMultiline}
        keyboardType={isDiscountField ? "numeric" : undefined}
      />

      {formField.additonalContent ? formField.additonalContent : null}
    </View>
  );
}

const styles = StyleSheet.create({
  formFieldContainer: { marginVertical: 5 },
  formInput: {
    backgroundColor: "white",
  },
});
