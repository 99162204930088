import React from "react";
import { View, Pressable } from "react-native";
import { Divider } from "react-native-paper";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList, SavedOfferEntry } from "../../../../types";
import { buildTripOffer } from "../../../../utilities";
import { ShoppingListItem } from "../ShoppingListItem/ShoppingListItem";

type CouponsScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Your Trip"
>;

interface Props {
  savedOfferEntry: SavedOfferEntry;
  navigation: CouponsScreenNavigationProp;
};

export function TripOfferItem({ savedOfferEntry, navigation }: Props) {
  const [key, savedOffer] = savedOfferEntry;
  const dealsCount = getDealsCount(savedOffer);

  return (
    <View key={key}>
      <Pressable
        onPress={() =>
          navigation.navigate("ListItemMatches", {
            key,
          })
        }
      >
        <ShoppingListItem item={savedOfferEntry} dealsCount={dealsCount} />
      </Pressable>
      <Divider />
    </View>
  );
}

function getDealsCount(item) {
  const cleanedTripItem = buildTripOffer(item);
  if (
    cleanedTripItem.cashbackOffers.length === 0 &&
    cleanedTripItem.flyerItems.length === 0 &&
    cleanedTripItem.onlineCoupons.length === 0
  ) {
    return 0;
  }
  const hasPhysicalCoupon = Object.keys(cleanedTripItem.coupon).includes(
    "discount"
  )
    ? 1
    : 0;

  return (
    hasPhysicalCoupon +
    cleanedTripItem.flyerItems.filter((flyerItem) => flyerItem.saved).length +
    cleanedTripItem.cashbackOffers.filter(
      (cashbackOffer) => cashbackOffer.saved
    ).length +
    cleanedTripItem.onlineCoupons.filter((onlineCoupon) => onlineCoupon.saved)
      .length
  );
}
