import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Chip, IconButton } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import useSWR from "swr";

import { deleteItem, fetcher } from "../../../../utilities";
import { SavedOfferMap, SavedOfferEntry } from "../../../../types";

interface Props {
  item: SavedOfferEntry;
  dealsCount: number;
}

export function ShoppingListItem({ item, dealsCount }: Props) {
  const { data: savedOffers } = useSWR<SavedOfferMap>("getCoupons", fetcher);
  const [key, listItem] = item;

  return (
    <View key={listItem.onlineCouponsSearchId} style={styles.container}>
      <View style={styles.row}>
        <View style={{ justifyContent: "center", marginRight: 8 }}>
          <IconButton
            icon="close-circle"
            color="#C76033"
            size={18}
            onPress={() => deleteItem(key, savedOffers)}
            accessibilityLabel='remove item from your list'
          />
        </View>
        <View style={{ justifyContent: "center" }}>
          <Text style={styles.title}>{listItem.name}</Text>
        </View>
      </View>
      <View style={styles.row}>
        {Boolean(dealsCount) && (
          <View style={styles.icon}>
            <Chip
              mode="flat"
              textStyle={{
                fontSize: 12,
                marginVertical: 1,
                color: "white",
                fontFamily: "montserrat-regular",
              }}
              style={styles.chip}
            >
              {dealsCount} Saved
            </Chip>
          </View>
        )}

        <View style={{ justifyContent: "center" }}>
          <MaterialCommunityIcons
            size={30}
            style={{ marginBottom: 0 }}
            name="chevron-right"
            color="black"
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: 24,
    marginLeft: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 56,
  },
  row: { flexDirection: "row" },
  icon: { justifyContent: "center", marginRight: 15 },
  chip: {
    height: 28,
    backgroundColor: "#A8CF92",
    color: "white",
  },
  title: {
    fontFamily: "gotham-light",
    color: "#000",
  },
});
