import React, { useState } from "react";
import { StyleSheet, View, SafeAreaView } from "react-native";
import {
  Title,
  Text,
  Avatar,
  Provider,
  DefaultTheme,
} from "react-native-paper";
import Moment from "moment";

import { firebase } from "../../../firebaseConfig";
import { SignUpScreenProps } from "../../../types";
import { TermsAndConditionsModal } from "../../../components/TermsAndConditionsModal";
import { cleanPostalCode, isValidPostalCode } from '../../../utilities/formUtils';

import { SignUpForm } from "./components/SignUpForm";
import { ErrorType, Error } from "./types";

export function SignUpScreen({ navigation }: SignUpScreenProps) {
  const [errorMessage, setErrorMessage] = useState<Error>({
    message: "",
    type: ErrorType.Empty,
  });
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const dbh = firebase.firestore();

  const toggleTermsModal = () => {
    setTermsModalOpen(!termsModalOpen);
  };

  const toggleTermsChecked = () => {
    setTermsChecked(!termsChecked);
  };

  const handleAgreeToTerms = () => {
    setTermsModalOpen(false);
    setTermsChecked(true);
  };

  const onLoginFailure = (errorType: ErrorType, errorMessage: string) => {
    setErrorMessage({ type: errorType, message: errorMessage });
  };

  const signInWithEmail = async (
    postalCode: string,
    email: string,
    password: string
  ) => {
    const cleanedPostalCode = cleanPostalCode(postalCode);

    if (isValidPostalCode(cleanedPostalCode)) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          const userRef = dbh.collection("users").doc(user.user?.uid);

          userRef.set(
            {
              postalCode: cleanedPostalCode,
              termsAccesptedAt: Moment.utc().format(),
            },
            { merge: true }
          );

          navigation.navigate("WelcomeScreen");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          if (errorCode == "auth/weak-password") {
            onLoginFailure(ErrorType.Password, "Weak Password");
          } else {
            onLoginFailure(ErrorType.EmailAddress, errorMessage);
          }
        });
    } else {
      onLoginFailure(ErrorType.Password, "Incorrect postal code");
    }
  };

  return (
    <Provider theme={DefaultTheme}>
      <SafeAreaView style={styles.container}>
        <TermsAndConditionsModal
          modalOpen={termsModalOpen}
          onClose={toggleTermsModal}
          onAgreeToTerms={handleAgreeToTerms}
        />
        <Avatar.Image size={80} source={require("../assets/savin-logo.png")} />
        <Title style={styles.titleText}>Create your login information</Title>
        <SignUpForm
          error={errorMessage}
          termsChecked={termsChecked}
          handleSubmit={signInWithEmail}
          toggleTermsModal={toggleTermsModal}
          toggleTermsChecked={toggleTermsChecked}
        />
        <View style={{ marginTop: 10 }}>
          <Text
            onPress={() => {
              navigation.navigate("SignIn");
            }}
          >
            Already have an account?
          </Text>
        </View>
      </SafeAreaView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    fontSize: 34,
    paddingVertical: 10,
    width: "80%",
    marginTop: 20,
  },
});
