import { StackNavigationProp, StackScreenProps } from "@react-navigation/stack";
import { UserCouponDetails } from "./components/UserCoupon/UserCoupon";

type AuthScreenNavigationProp = StackNavigationProp<AuthStackParamList, "Auth">;

type SignInScreenNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "SignIn"
>;

type SignUpScreenNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "SignUp"
>;

type AnonymoudSignUpScreenNavigationProp = StackNavigationProp<
  AuthStackParamList,
  "AnonymousSignUp"
>;

type AuthScreenRouteProp = StackScreenProps<AuthStackParamList, "Auth">;
type SignInScreenRouteProp = StackScreenProps<AuthStackParamList, "SignIn">;
type SignUpScreenRouteProp = StackScreenProps<AuthStackParamList, "SignUp">;
type AnonymousSignUpScreenRouteProp = StackScreenProps<
  AuthStackParamList,
  "AnonymousSignUp"
>;

export type AuthScreenProps = {
  route: AuthScreenRouteProp;
  navigation: AuthScreenNavigationProp;
};

export type SignInScreenProps = {
  route: SignInScreenRouteProp;
  navigation: SignInScreenNavigationProp;
};

export type SignUpScreenProps = {
  route: SignUpScreenRouteProp;
  navigation: SignUpScreenNavigationProp;
};

export type AnonmyousSignUpScreenProps = {
  route: AnonymousSignUpScreenRouteProp;
  navigation: AnonymoudSignUpScreenNavigationProp;
};

export type RootStackParamList = {
  AuthNavigation: undefined;
  Root: undefined;
  Deals: undefined;
  UserCouponForm: {
    couponToUpdate: UserCouponDetails;
    couponId: string;
  };
  OnboardingWalkthrough: undefined;
  WelcomeScreen: undefined;
  NotFound: undefined;
  SavedItemMatches: {
    key: string;
  };
  SearchItemMatches: {
    searchQuery: string;
  };
  HomeItemMatches: {
    key: string;
  };
  ListItemMatches: {
    key: string;
  };
  Favourites: { key: any; endpoint: string };
  Search: undefined;
  "Your Trip": { key: any; endpoint: string };
  Profile: {
    modalOpen?: boolean;
  };
};

export type AuthStackParamList = {
  Auth: undefined;
  SignIn: undefined;
  SignUp: undefined;
  AnonymousSignUp: undefined;
  OnboardingWalkthrough: undefined;
  WelcomeScreen: undefined;
};

export type MainList = {
  Deals: undefined;
  Favourites: undefined;
  "My Coupons": undefined;
  Search: undefined;
  Profile: undefined;
  "Your Trip": undefined;
};

export type HomeParamList = {
  Deals: undefined;
  HomeItemMatches: any;
  Profile: undefined;
};

export type CouponsParamList = {
  Coupons: undefined;
  SavedItemMatches: any;
};

export type SavedTabsParamList = {
  Favourited: undefined;
  MyCoupons: undefined;
};

export type SearchParamList = {
  Search: undefined;
  SearchItemMatches: any;
};

export type ProfileParamList = {
  Profile: undefined;
};

export type ShoppingListParamList = {
  List: undefined;
  ListItemMatches: any;
};

export type SearchData = {
  matches: SavedOffer[];
};

export type OfferMap = Record<string, Offer>;
export type OfferEntry = [string, Offer];

export interface Offer {
  saved: boolean;
  cashbackOffers: CashbackOffer[];
  flyerItems: FlyerItem[];
  image_url: string;
  name: string;
  onlineCoupons: OnlineCoupon[];
  pointsOffers: [];
}

export type SavedOfferMap = Record<string, SavedOffer>;
export type SavedOfferEntry = [string, SavedOffer];

export interface SavedOffer {
  cashbackOffers: CashbackOffer[];
  cashbackOffersSearchId: string;
  flyerItems: FlyerItem[];
  flyerItemSearchId: string;
  maxCashback: number;
  minFlyerPrice: number;
  minPotentialPrice: number;
  name: string;
  onlineCoupons: OnlineCoupon[];
  onlineCouponsSearchId: string;
  coupon: Coupon;
  saved: boolean;
}

export interface Coupon {
  createdAt: string;
  product: string;
  updatedAt: string;
  discount?: string;
  expiry?: string;
  notes?: string;
}

export interface OnlineCoupon {
  created_at: string;
  deal: string;
  description: string;
  discarded_at?: null;
  id: string;
  image_url: string;
  logo_url: string;
  provider: string;
  saved: boolean;
  savings: number;
  size: number | null;
  updated_at: string;
  valid_to: string | null;
  url: string;
}

export interface FlyerItem {
  clipping_image_url: string;
  created_at: string;
  current_price: number | null;
  discarded_at?: null;
  flipp_item_id: number;
  hash_id: string;
  id: string;
  logo_url: string;
  merchant_id: number;
  merchant_name: string;
  name: string;
  sale_story: string;
  saved: boolean;
  updated_at: string;
  valid_from: string | null;
  valid_to: string | null;
}

export interface CashbackOffer {
  cash_back: number;
  created_at: string;
  description: string;
  discarded_at?: null;
  id: string;
  image_url: string;
  image_url_high_res: string | null;
  item_id: number;
  logo_url: string;
  max_claims: number;
  name: string;
  offer_url: string;
  provider: string;
  saved: boolean;
  updated_at: string;
  valid_from: string | null;
  valid_to: string | null;
}

export interface ItemTypes {
  listName: string;
  type: ItemType;
}

export enum ItemType {
  FlyerItem = "flyer_item",
  Coupon = "coupon",
  CashbackItem = "cashback_item",
}
