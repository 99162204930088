import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Button, TextInput, IconButton } from "react-native-paper";

import { Error } from "../types";

interface Props {
  error: Error;
  termsChecked: boolean;
  handleSubmit: (postalCode: string, email: string, password: string) => void;
  toggleTermsModal(): void;
  toggleTermsChecked(): void;
};

export function SignUpForm({
  handleSubmit,
  error,
  termsChecked,
  toggleTermsModal,
  toggleTermsChecked,
}: Props) {
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const buttonDisabled = !postalCode || !email || !password || !termsChecked;

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        mode="outlined"
        label="Postal Code"
        value={postalCode}
        onChangeText={(newPostalCode) => setPostalCode(newPostalCode)}
      />
      <TextInput
        style={styles.input}
        mode="outlined"
        label="Email"
        textContentType="emailAddress"
        keyboardType="email-address"
        autoCorrect={false}
        value={email}
        onChangeText={(newEmail) => setEmail(newEmail)}
      />
      <TextInput
        style={styles.input}
        mode="outlined"
        label="Password"
        textContentType="newPassword"
        value={password}
        onChangeText={(newPassword) => setPassword(newPassword)}
        secureTextEntry={true}
        autoCorrect={false}
      />
      <View style={styles.terms}>
        <Text>
          I agree with the{" "}
          <Text style={styles.termsButton} onPress={() => toggleTermsModal()}>
            Terms and Conditions
          </Text>
        </Text>
        <IconButton
            icon={termsChecked ? "checkbox-marked" : "checkbox-blank-outline"}
            size={20}
            onPress={() => toggleTermsChecked()}
            accessibilityLabel={termsChecked ? 'un-check checkbox' : 'check checkbox'}
          />
      </View>
      <Text style={styles.error}>{error.message}</Text>
      <Button
        mode="contained"
        compact
        onPress={() => handleSubmit(postalCode, email, password)}
        style={styles.button}
        color="#A8CF92"
        disabled={buttonDisabled}
      >
        Sign Up
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
  },
  input: {
    marginVertical: 2,
    width: "80%",
    textAlign: "left",
    backgroundColor: "white",
  },
  error: {
    fontSize: 18,
    textAlign: "center",
    color: "red",
    width: "80%",
  },
  button: { width: "80%", marginBottom: 10 },
  terms: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  termsButton: {
    fontWeight: "bold",
    alignSelf: "center",
    textDecorationLine: "underline",
  },
});
