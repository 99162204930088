import React from "react";
import { View, StyleSheet } from "react-native";
import { Portal, Button, Dialog, Paragraph } from "react-native-paper";
import translations from "./translations/en.json";

interface Props {
  visible: boolean;
  hideModal: () => void;
  onDelete: () => void;
}

export function DeleteItemModal({ visible, hideModal, onDelete }: Props) {
  return (
    <Portal>
      <Dialog visible={visible} onDismiss={hideModal}>
        <Dialog.Title>{translations.title}</Dialog.Title>
        <Dialog.Content>
          <Paragraph>{translations.warning}</Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <View style={styles.dialogButton}>
            <Button onPress={hideModal} color="#A8CF92">
              {translations.cancel}
            </Button>
          </View>
          <View style={styles.dialogButton}>
            <Button onPress={onDelete} color="#A8CF92">
              {translations.delete}
            </Button>
          </View>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

const styles = StyleSheet.create({
  dialogButton: {
    marginHorizontal: 5,
    marginBottom: 10,
  },
});
