import React from "react";
import { Text, StyleSheet } from "react-native";

import {
  CashbackOffer,
  FlyerItem,
  OnlineCoupon,
  ItemType,
} from "../../../../../../types";

interface Props {
  offerItem: CashbackOffer | FlyerItem | OnlineCoupon;
  offerType: ItemType;
};

export function OfferPrice({ offerItem, offerType }: Props) {
  const nullState = <></>;

  switch (offerType) {
    case ItemType.CashbackItem:
      if ((offerItem as CashbackOffer).cash_back) {
        return (
          <Text style={styles.savings}>
            ${(offerItem as CashbackOffer).cash_back.toFixed(2)} Cash Back
          </Text>
        );
      }
    case ItemType.Coupon:
      if ((offerItem as OnlineCoupon).deal) {
        return (
          <Text style={styles.savings}>{(offerItem as OnlineCoupon).deal}</Text>
        );
      }
    case ItemType.FlyerItem:
      if ((offerItem as FlyerItem).current_price) {
        return (
          <Text style={styles.savings}>
            ${(offerItem as FlyerItem).current_price?.toFixed(2)}
          </Text>
        );
      }
    default:
      nullState;
  }

  return nullState;
}

const styles = StyleSheet.create({
  savings: {
    fontSize: 16,
    fontFamily: "gotham-bold",
    marginBottom: 5,
    color: "#A8CF92",
  },
});
