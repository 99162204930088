import React, { useContext } from "react";
import { View, FlatList, StyleSheet } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import useSWR, { mutate } from "swr";
import * as Crypto from "expo-crypto";
import Moment from "moment";

import {
  RootStackParamList,
  OfferMap,
  OfferEntry,
  SavedOfferMap,
} from "../../types";
import { fetcher, deleteItem, track } from "../../utilities";
import { EmptyState } from "../../components/EmptyState";
import { firebase } from "../../firebaseConfig";
import { AuthContext } from "../../AuthContext";

import { HomeListItem } from "./components/HomeListItem";
import { HomeScreenHeader } from "./components/HomeScreenHeader";

type HomeScreenNavigationProp = StackNavigationProp<RootStackParamList, "Root">;
type HomecreenRouteProp = RouteProp<RootStackParamList, "Root">;

interface Props {
  navigation: HomeScreenNavigationProp;
  route: HomecreenRouteProp;
}

export function HomeScreen({ navigation, route }: Props) {
  const { colors } = useTheme();
  const user = useContext(AuthContext);

  let { data } = useSWR<OfferMap>("getMatches", fetcher);
  const { data: savedOffers } = useSWR<SavedOfferMap>("getCoupons", fetcher);

  if (!data || (!data && !savedOffers)) {
    return (
      <View style={styles.container}>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  if (data.updatedAt) {
    delete data.updatedAt;
  }

  const sortedData = Object.entries(data).sort((dealA, dealB) => {
    return dealB[1].created_at - dealA[1].created_at;
  });

  const handleSaveState = async (deal: OfferEntry) => {
    const now = Moment.utc().format();
    const [key, offer] = deal;
    const isSaved = offer.saved;
    const analyticsAction = isSaved ? "DeleteDeal" : "SaveDeal";

    if (savedOffers && data) {
      data[key].saved = !offer.saved;

      mutate(
        "getMatches",
        {
          ...data,
          updatedAt: now,
        },
        false
      );

      const digestId = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        offer.name
      );

      if (!isSaved) {
        savedOffers[digestId] = {
          ...offer,
          coupon: {
            createdAt: now,
            product: offer.name,
            updatedAt: now,
          },
          saved: true,
        };

        mutate(
          "getCoupons",
          {
            ...savedOffers,
            updatedAt: now,
          },
          false
        );

        let manageItemSavedState = firebase
          .functions()
          .httpsCallable("manageItemSavedState");

        manageItemSavedState({
          name: offer.name,
          id: digestId,
        }).then((result) => {
          console.log("result", result);
        });
      } else {
        deleteItem(digestId, savedOffers);
      }
      track(`${route.name}.${analyticsAction}`, user.uid);
    }
  };

  return (
    <View
      style={styles.container}
    >
      <FlatList
        style={styles.flatlistContainer}
        ListHeaderComponent={<HomeScreenHeader navigation={navigation} />}
        ListEmptyComponent={
          <EmptyState
            icon="alert-circle-outline"
            emptyStateMessage="Uh oh, there are currently no hot deals."
          />
        }
        data={sortedData}
        renderItem={({ item }) => (
          <HomeListItem
            deal={item}
            navigation={navigation}
            onSaveOffer={handleSaveState}
          />
        )}
        keyExtractor={(item) => item[0]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    height: "100%",
  },
  flatlistContainer: {
    overflow: "scroll",
  },
  scrolledContainer: {
    elevation: 5,
  },
});
