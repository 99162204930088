import React from "react";
import { View, Text, Image, StyleSheet, Pressable } from "react-native";
import { Surface, IconButton } from "react-native-paper";
import Moment from "moment";
import * as WebBrowser from "expo-web-browser";

import {
  CashbackOffer,
  FlyerItem,
  OnlineCoupon,
  ItemType,
  ItemTypes,
} from "../../../../types";
import { OfferPrice } from "./components/OfferPrice/OfferPrice";

interface Props {
  offerItem: CashbackOffer | FlyerItem | OnlineCoupon;
  onPress: (itemId: string, itemTypes: ItemTypes, itemIndex: number) => void;
  itemTypes: {
    listName: string;
    type: ItemType;
  };
  itemIndex: number;
};

export function OfferItem({ offerItem, onPress, itemTypes, itemIndex }: Props) {
  let date = offerItem.valid_to;

  if (date && date.length > 0) {
    date = Moment(date).format("DD/MM/YY");
  }

  const getOfferUrl = () => {
    switch (itemTypes.type) {
      case ItemType.CashbackItem:
        return (offerItem as CashbackOffer).offer_url;
      case ItemType.Coupon:
        return (offerItem as OnlineCoupon).url;
      case ItemType.FlyerItem:
        return encodeURI("https://flipp.com/search/" + (offerItem as FlyerItem).name)
      default:
        null;
    }
  };

  const offerUrl = getOfferUrl();

  return (
    <Pressable
      onPress={() => {
        offerUrl && handleOpenLink(offerUrl);
      }}
    >
      <Surface style={styles.surface}>
        <View style={styles.itemCardContainer}>
          <View style={styles.topSection}>
            <Image
              style={styles.merchantLogo}
              source={{ uri: offerItem.logo_url }}
              resizeMode="contain"
              accessibilityLabel={
                (offerItem as CashbackOffer).provider ||
                (offerItem as FlyerItem).merchant_name
              }
            />
            <IconButton
              icon={offerItem.saved ? "cart" : "cart-plus"}
              color={offerItem.saved ? "#C76033" : "#D6D6D6"}
              size={18}
              onPress={() =>
                onPress && onPress(offerItem.id, itemTypes, itemIndex)
              }
              style={styles.icon}
              accessibilityLabel={offerItem.saved ? 'Remove item from your list' : 'Add item to your list'}
            />
          </View>
          <View style={styles.itemInfo}>
            <Image
              style={styles.itemImage}
              source={{
                uri:
                  (offerItem as CashbackOffer).image_url ||
                  (offerItem as FlyerItem).clipping_image_url,
              }}
              resizeMode="contain"
            />
            <Text numberOfLines={2} style={styles.title}>
              {(offerItem as FlyerItem).name ||
                (offerItem as OnlineCoupon).description}
            </Text>
            <OfferPrice offerItem={offerItem} offerType={itemTypes.type} />
          </View>
          <View>
            <Text style={styles.expiry}>{date && `EXPIRES ${date}`}</Text>
          </View>
        </View>
      </Surface>
    </Pressable>
  );
}

function handleOpenLink(url: string) {
  WebBrowser.openBrowserAsync(url);
}

const styles = StyleSheet.create({
  surface: {
    minHeight: 192,
    elevation: 5,
    margin: 7,
    borderRadius: 4,
    padding: 6,
    marginVertical: 10,
    width: 152,
  },
  icon: {
    margin: 0,
    alignSelf: "flex-end",
  },
  title: {
    fontSize: 12,
    marginTop: 8,
    textAlign: "center",
    fontFamily: "montserrat-regular",
    color: "black",
    marginBottom: 6,
  },
  itemImage: {
    width: 80,
    height: 60,
  },
  itemCardContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  itemInfo: {
    alignItems: "center",
  },
  expiry: {
    fontFamily: "montserrat-regular",
    fontSize: 10,
    color: "#979797",
    textAlign: "left",
    marginBottom: 2,
  },
  topSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
  },
  merchantLogo: {
    width: 44,
    height: 24,
  },
});
