import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  Provider,
  Text,
  DefaultTheme,
  Button,
  Headline
} from "react-native-paper";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { mutate } from "swr";
import Moment from "moment";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";

import { firebase } from "../../firebaseConfig";
import { AuthContext } from "../../AuthContext";
import { RootStackParamList } from "../../types";
import { track } from "../../utilities/Analytics";
import { UserCouponDetails } from "../../components/UserCoupon/UserCoupon";

import { CouponFormField } from "./components/CouponFormField";

type UserCouponFormNavigationProp = StackNavigationProp<
  RootStackParamList,
  "UserCouponForm"
>;
type UserCouponFormRouteProp = RouteProp<RootStackParamList, "UserCouponForm">;

interface CouponPayload extends UserCouponDetails {
  updatedAt?: string;
  createdAt?: string;
}

interface Props {
  navigation: UserCouponFormNavigationProp;
  route: UserCouponFormRouteProp;
}

export function UserCouponForm({ navigation, route }: Props) {
  const dbh = firebase.firestore();
  const user = React.useContext(AuthContext);

  const { couponToUpdate, couponId } = route.params;

  const isUpdating = Boolean(couponToUpdate);

  const [isError, setIsError] = React.useState(false);
  const [product, setProduct] = useState(
    couponToUpdate ? couponToUpdate.product : ""
  );
  const [notes, setNotes] = useState(
    couponToUpdate?.notes ? couponToUpdate.notes : ""
  );
  const [discount, setDiscount] = useState(
    couponToUpdate?.discount ? couponToUpdate.discount : ""
  );
  const [expiry, setExpiry] = useState({
    expiryDate: couponToUpdate?.expiry
      ? new Date(couponToUpdate.expiry)
      : new Date(),
    expiryString: couponToUpdate?.expiry || "",
  });

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const buttonDisabled = () => {
    if (isUpdating) {
      const {
        product: existingProduct,
        discount: existingDiscount,
        expiry: existingExpiry,
        notes: existingNotes,
      } = couponToUpdate;
      if (
        product === existingProduct &&
        discount === existingDiscount &&
        expiry.expiryString === existingExpiry &&
        notes === existingNotes
      ) {
        return true;
      }
      return false;
    }
    if (product.length > 0) return false;
    else return true;
  };

  const handleConfirm = (date: Date) => {
    setIsDatePickerVisible(false);
    setExpiry({ expiryDate: date, expiryString: date.toLocaleDateString() });
  };

  const onSubmit = () => {
    var now = Moment.utc().format();
    if (buttonDisabled()) {
      setIsError(true);
    } else {
      console.log("success");
      setIsError(false);

      if (isUpdating) {
        const updatePayload: CouponPayload = {
          product,
          notes,
          discount,
          expiry: expiry.expiryString,
          updatedAt: now,
        };
        dbh
          .collection("users")
          .doc(user.uid)
          .collection("coupons")
          .doc(couponId)
          .update(updatePayload)
          .then(() => {
            if (product !== couponToUpdate.product) {
              mutate("getCoupons", null, true);
            }
            navigation.goBack();
          });
      } else {
        const createPayload: CouponPayload = {
          product,
          notes,
          discount,
          expiry: expiry.expiryString,
          createdAt: now,
          updatedAt: now,
        };

        dbh
          .collection("users")
          .doc(user.uid)
          .collection("coupons")
          .add(createPayload)
          .then((result) => {
            mutate("getCoupons");

            track(`${route.name}.CouponAdded`, user.uid, {
              hasNotes: !!notes,
              hasDiscount: !!discount,
              hasExpiry: !!expiry.expiryString,
            });

            navigation.navigate("SavedItemMatches", {
              key: result.id,
            });
          });
      }
    }
  };

  const formFields = [
    {
      label: "Product Name",
      value: product,
      onChange: setProduct,
    },
    {
      label: "Discount",
      value: discount,
      onChange: setDiscount,
    },
    {
      label: "Expiry Date",
      value: expiry.expiryString,
      onChange: (item) => setExpiry({ ...expiry, expiryString: item }),
      onTouch: () => {
        setIsDatePickerVisible(true);
      },
      additonalContent: (
        <DateTimePickerModal
          isVisible={isDatePickerVisible}
          mode="date"
          date={expiry.expiryDate}
          onConfirm={handleConfirm}
          onCancel={() => {
            setIsDatePickerVisible(false);
          }}
        />
      ),
    },
    {
      label: "Notes",
      value: notes,
      onChange: setNotes,
      allowMultiline: true,
    },
  ];

  return (
    <Provider theme={DefaultTheme}>
      <View style={styles.addCouponModal}>
        <Headline style={{marginVertical: 20}}>Add a coupon</Headline>
        {formFields.map((field, index) => {
          return (
            <CouponFormField
              formField={field}
              key={index}
              allowMultiline={field.allowMultiline}
              showCamera={showModal}
            />
          );
        })}

        {isError && (
          <Text style={styles.error}>{"There's an error in your form"}</Text>
        )}
        <View style={styles.buttonContainer}>
          <Button
            mode="contained"
            onPress={onSubmit}
            disabled={buttonDisabled()}
            style={{ width: "100%" }}
          >
            {isUpdating ? "Update" : "Add Coupon"}
          </Button>
        </View>
        <View style={styles.buttonContainer}>
          <Button
            mode="outlined"
            onPress={() => navigation.goBack()}
            style={{ width: "100%" }}
          >
            Cancel
          </Button>
        </View>
      </View>
    </Provider>
  );
}

const styles = StyleSheet.create({
  addCouponModal: { flex: 1, backgroundColor: "white", padding: 20, paddingTop: 50 },
  error: { color: "red" },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
});
