import React, { useState } from "react";
import { StyleSheet, View, SafeAreaView } from "react-native";
import {
  Title,
  Text,
  Avatar,
  Provider,
  DefaultTheme,
  TextInput,
  Button,
  IconButton,
} from "react-native-paper";
import Moment from "moment";

import { firebase } from "../../../firebaseConfig";
import { AnonmyousSignUpScreenProps } from "../../../types";
import { TermsAndConditionsModal } from "../../../components/TermsAndConditionsModal";
import { cleanPostalCode, isValidPostalCode } from '../../../utilities/formUtils';

export function AnonymousSignUpScreen({
  navigation,
}: AnonmyousSignUpScreenProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [postalCode, setPostalCode] = useState("");

  const dbh = firebase.firestore();

  const toggleTermsModal = () => {
    setTermsModalOpen(!termsModalOpen);
  };

  const toggleTermsChecked = () => {
    setTermsChecked(!termsChecked);
  };

  const handleAgreeToTerms = () => {
    setTermsModalOpen(false);
    setTermsChecked(true);
  };

  const signInAnonymously = async () => {
    const cleanedPostalCode = cleanPostalCode(postalCode);

    if (isValidPostalCode(cleanedPostalCode)) {
      setErrorMessage("");
      await firebase
        .auth()
        .signInAnonymously()
        .then((user) => {
          const userRef = dbh.collection("users").doc(user.user?.uid);

          userRef.set(
            {
              postalCode: cleanedPostalCode,
              termsAccesptedAt: Moment.utc().format(),
            },
            { merge: true }
          );

          navigation.navigate("WelcomeScreen");
        });
    } else {
      setErrorMessage("Incorrect postal code");
    }
  };

  return (
    <Provider theme={DefaultTheme}>
      <SafeAreaView style={styles.container}>
        <TermsAndConditionsModal
          modalOpen={termsModalOpen}
          onClose={toggleTermsModal}
          onAgreeToTerms={handleAgreeToTerms}
        />
        <Avatar.Image size={80} source={require("../assets/savin-logo.png")} />
        <Title style={styles.titleText}>See the latest deals near you</Title>
        <TextInput
          style={styles.input}
          mode="outlined"
          label="Postal Code"
          value={postalCode}
          onChangeText={(newPostalCode) => setPostalCode(newPostalCode)}
        />
        <View style={styles.terms}>
          <Text>
            I agree with the{" "}
            <Text style={styles.termsButton} onPress={() => toggleTermsModal()}>
              Terms and Conditions
            </Text>
          </Text>
          <IconButton
            icon={termsChecked ? "checkbox-marked" : "checkbox-blank-outline"}
            size={20}
            onPress={() => toggleTermsChecked()}
            accessibilityLabel={termsChecked ? 'un-check checkbox' : 'check checkbox'}
          />
        </View>
        <Text style={styles.error}>{errorMessage}</Text>
        <Button
          mode="contained"
          compact
          onPress={() => signInAnonymously()}
          style={styles.button}
          color="#A8CF92"
          disabled={!postalCode || !termsChecked}
        >
          Continue
        </Button>
      </SafeAreaView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    fontSize: 34,
    paddingVertical: 10,
    width: "80%",
    marginTop: 20,
  },
  input: {
    marginVertical: 2,
    width: "80%",
    textAlign: "left",
    backgroundColor: "white",
  },
  terms: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  termsButton: {
    fontWeight: "bold",
    alignSelf: "center",
    textDecorationLine: "underline",
  },
  error: {
    fontSize: 18,
    textAlign: "center",
    color: "red",
    width: "80%",
  },
  button: { width: "80%", marginBottom: 10 },
});
