import React, { useState, useContext } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { ActivityIndicator, Snackbar, useTheme } from "react-native-paper";
import { useRoute, useNavigation } from "@react-navigation/native";
import useSWR, { mutate } from "swr";
import Moment from "moment";

import { firebase } from "../../firebaseConfig";
import { fetcher, track, deleteItem, buildTripOffer } from "../../utilities";
import { ItemMatches } from "../../components/ItemMatches";
import { UserCoupon } from "../../components/UserCoupon";
import { DeleteItemModal } from "../../components/DeleteItemModal";
import { AuthContext } from "../../AuthContext";
import { SavedOfferMap } from "../../types";
import { MutationData } from "../types";

interface Props {
  itemKey: string;
  tripItem?: boolean;
}

export function SavedListItemMatches({ itemKey, tripItem }: Props) {
  const user = useContext(AuthContext);
  const route = useRoute();
  const { colors } = useTheme();
  const navigation = useNavigation();

  const { data: savedOffers } = useSWR<SavedOfferMap>("getCoupons", fetcher);

  const [snackVisible, setSnackVisible] = useState(false);
  const [saveState, setSaveState] = useState<boolean>();
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [mutationData, setMutationData] = useState<MutationData>();

  if (!savedOffers) {
    return (
      <View style={styles.savedItemMatches}>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  let savedOffer = savedOffers[itemKey];

  if (!savedOffer) {
    return (
      <View style={styles.savedItemMatches}>
        <View style={{ height: "100%", justifyContent: "center" }}>
          <ActivityIndicator animating={true} color={colors.primary} />
        </View>
      </View>
    );
  }

  if (tripItem) {
    savedOffer = buildTripOffer(savedOffer);
  }

  const userCoupon = savedOffer.coupon;
  const hasPhysicalCoupon = Object.keys(userCoupon).includes("discount");

  const handleDeleteOffer = () => {
    if (!mutationData) {
      return;
    }

    const { itemId, itemTypes, itemIndex } = mutationData;
    const { listName, type } = itemTypes;
    const searchId = listName + "SearchId";

    setSaveState(true);
    setModalVisible(false);
    setSnackVisible(false);

    let updatedItem = savedOffer;
    updatedItem[listName][itemIndex].saved = false;

    if (savedOffers) {
      mutate(
        "getCoupons",
        {
          ...savedOffers,
          [itemKey]: {
            ...updatedItem,
          },
          updatedAt: Moment.utc().format(),
        },
        false
      );

      setSnackVisible(true);

      track(`${route.name}.ManageItemSaveState`, user.uid, {
        saved: false,
        itemType: type,
      });

      var manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");
      manageItemSavedState({
        searchId: savedOffer[searchId],
        itemId,
        itemType: type,
        action: "delete",
      }).then((result) => {
        console.log("result", result);
      });
    }
  };

  const handleSaveState = async ({
    itemId,
    itemTypes,
    itemIndex,
  }: MutationData) => {
    const { listName, type } = itemTypes;
    const searchId = listName + "SearchId";
    const itemSaved = Boolean(savedOffer[listName][itemIndex].saved);

    if (tripItem && itemSaved) {
      setModalVisible(true);
      setMutationData({
        itemId,
        itemTypes,
        itemIndex,
      });
      return;
    }

    setSaveState(itemSaved);
    setSnackVisible(false);

    let updatedItem = savedOffer;
    updatedItem[listName][itemIndex].saved = !itemSaved;

    if (savedOffers) {
      mutate(
        "getCoupons",
        {
          ...savedOffers,
          [itemKey]: {
            ...updatedItem,
          },
          updatedAt: Moment.utc().format(),
        },
        false
      );

      setSnackVisible(true);

      track(`${route.name}.ManageItemSaveState`, user.uid, {
        saved: !itemSaved,
        itemType: type,
      });

      var manageItemSavedState = firebase
        .functions()
        .httpsCallable("manageItemSavedState");
      manageItemSavedState({
        searchId: savedOffer[searchId],
        itemId,
        itemType: type,
        action: itemSaved ? "delete" : "save",
      }).then((result) => {
        console.log("result", result);
      });
    }
  };

  return (
    <View style={styles.savedItemMatches}>
      <DeleteItemModal
        visible={modalVisible}
        hideModal={() => {
          setModalVisible(false);
        }}
        onDelete={() => {
          handleDeleteOffer();
        }}
      />
      <DeleteItemModal
        visible={deleteModalVisible}
        hideModal={() => {
          setDeleteModalVisible(false);
        }}
        onDelete={() => {
          deleteItem(itemKey, savedOffers);
          navigation.goBack();
        }}
      />
      <ScrollView>
        <View style={styles.scrollContainer}>
          {savedOffer ? (
            <ItemMatches
              item={savedOffer}
              offerIsFavourited
              onManageSaveState={handleSaveState}
              userCoupon={
                hasPhysicalCoupon ? (
                  <UserCoupon couponDetails={userCoupon} couponId={itemKey} />
                ) : undefined
              }
              onIconPress={() => {
                setDeleteModalVisible(true);
              }}
            />
          ) : (
            <View>
              <Text>No information available about this deal</Text>
            </View>
          )}
        </View>
      </ScrollView>
      <Snackbar
        duration={2500}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
      >
        Item {saveState ? "deleted" : "saved"}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  savedItemMatches: {
    backgroundColor: "white",
    height: "100%",
  },
  scrollContainer: {
    padding: 10,
  },
});
