import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import {
  AuthScreen,
  SignInScreen,
  SignUpScreen,
  AnonymousSignUpScreen,
} from "../screens/auth";
import { AuthStackParamList } from "../types";

const Auth = createStackNavigator<AuthStackParamList>();

export function AuthNavigator() {
  return (
    <Auth.Navigator initialRouteName="Auth">
      <Auth.Screen
        name="Auth"
        component={AuthScreen}
        options={{ headerShown: false }}
      />
      <Auth.Screen
        name="SignIn"
        component={SignInScreen}
        options={{ headerShown: false }}
      />
      <Auth.Screen
        name="SignUp"
        component={SignUpScreen}
        options={{ headerShown: false }}
      />
      <Auth.Screen
        name="AnonymousSignUp"
        component={AnonymousSignUpScreen}
        options={{ headerShown: false }}
      />
    </Auth.Navigator>
  );
}
