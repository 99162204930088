import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ActivityIndicator, Searchbar, useTheme } from "react-native-paper";
import useSWR from "swr";

import { fetcher } from "../../utilities/fetcher";
import { SavedOfferMap, SavedOfferEntry } from "../../types";

import { SavedList } from "./components/SavedList";
import { CouponsScreenNavigationProp } from "./types";
import { buildSavedOffersList } from "./utilities";

interface Props {
  navigation: CouponsScreenNavigationProp;
  filteredByCoupons?: boolean;
}

export function CouponsScreen({
  navigation,
  filteredByCoupons = false,
}: Props) {
  const { colors } = useTheme();
  const { data: savedOffers } = useSWR<SavedOfferMap>("getCoupons", fetcher);

  const [searchData, setSearchData] = useState<SavedOfferEntry[]>();
  const [searchQuery, setSearchQuery] = useState("");

  const sortedSavedOffers = buildSavedOffersList(
    filteredByCoupons,
    savedOffers
  );

  const onSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);

    if (searchQuery.length === 0) {
      setSearchData(sortedSavedOffers);
    }

    const searchResults = sortedSavedOffers.filter((entry) => {
      let [key, savedOffer] = entry;
      if (key !== "updatedAt" && key !== "updatedItem") {
        return savedOffer.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }
    });
    setSearchData(searchResults);
  };

  return (
    <View style={styles.couponsContainer}>
      <View style={styles.searchBar}>
        <Searchbar
          placeholder={
            filteredByCoupons ? "Search Your Coupons" : "Search Your Favourites"
          }
          onChangeText={(text) => onSearch(text)}
          value={searchQuery}
          inputStyle={{ fontSize: 16 }}
        />
      </View>
      <View style={styles.scrollableContainer}>
        {!savedOffers ? (
          <View style={{ height: "100%", justifyContent: "center" }}>
            <ActivityIndicator animating={true} color={colors.primary} />
          </View>
        ) : (
          <SavedList
            isUserCoupon={filteredByCoupons}
            hasSearchQuery={Boolean(searchQuery)}
            navigation={navigation}
            savedOffersList={
              searchQuery && searchData ? searchData : sortedSavedOffers
            }
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  couponsContainer: {
    backgroundColor: "#FEFEFE",
    height: "100%",
  },
  searchBar: {
    paddingTop: 16,
    paddingHorizontal: 16,
    marginBottom: 1,
  },
  scrollableContainer: {
    flex: 1,
  },
});
