import { SavedOfferMap, SavedOffer, SavedOfferEntry } from "../../types";

export function buildSavedOffersList(
  filteredByCoupons: boolean,
  savedOffers?: SavedOfferMap
) {
  const savedOffersWithDeals: SavedOfferEntry[] = [];

  Object.entries(savedOffers || {}).map((entry) => {
    const [key, savedOffer] = entry;
    if (key !== "updatedAt" && key !== "updatedItem" && hasDeals(savedOffer)) {
      if (
        !filteredByCoupons ||
        (filteredByCoupons && savedOffer.coupon.discount !== undefined)
      ) {
        savedOffersWithDeals.push(entry);
      }
    }
  });

  return savedOffersWithDeals.sort(
    (a: SavedOfferEntry, b: SavedOfferEntry) => parseDate(b) - parseDate(a)
  );
}

function parseDate(savedOffer: SavedOfferEntry) {
  return (
    Date.parse(savedOffer[1].coupon.createdAt) ||
    new Date(savedOffer[1].coupon.createdAt._seconds * 1000).valueOf()
  );
}

function hasDeals(savedOffer: SavedOffer) {
  const { cashbackOffers, flyerItems, onlineCoupons, coupon } = savedOffer;
  const hasPhysicalCoupon = Object.keys(coupon).includes("discount");
  const hasOffers =
    cashbackOffers.length > 0 ||
    flyerItems.length > 0 ||
    onlineCoupons.length > 0;

  return hasPhysicalCoupon || hasOffers;
}
