import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Button, TextInput } from "react-native-paper";

interface Props {
  handleSubmit: (email: string, password: string) => void;
  handleReset: (email: string) => void;
  errorMessage: string;
};

export function SignInForm({ handleSubmit, handleReset, errorMessage }: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <View style={styles.container}>
      <TextInput
        textAlign="left"
        style={styles.input}
        label="Email"
        value={email}
        keyboardType="email-address"
        textContentType="emailAddress"
        autoCompleteType="email"
        autoCorrect={false}
        onChangeText={(newEmail) => setEmail(newEmail)}
        mode="outlined"
        selectionColor="#A8CF92"
        
      />
      <TextInput
        textAlign="left"
        style={styles.input}
        label="Password"
        textContentType="password"
        value={password}
        onChangeText={(newPassword) => setPassword(newPassword)}
        autoCompleteType="password"
        autoCorrect={false}
        secureTextEntry
        mode="outlined"
      />
      <Text style={styles.errorMessage}>{errorMessage}</Text>
      <Button
        mode="contained"
        compact
        onPress={() => handleSubmit(email, password)}
        style={styles.button}
        color="#A8CF92"
        disabled={!email || !password}
      >
        Sign In
      </Button>

      <Button
        mode="outlined"
        compact
        onPress={() => handleReset(email)}
        style={styles.button}
        labelStyle={{ color: "black" }}
      >
        Forgot Password
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { width: "100%", alignItems: "center" },
  input: {
    marginVertical: 2,
    width: "80%",
    backgroundColor: "white",
  },
  errorMessage: {
    fontSize: 18,
    textAlign: "center",
    color: "red",
    width: "80%",
  },
  button: {
    width: "80%",
    marginBottom: 10,
  },
});
