import React, { useState, useContext } from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { Surface, IconButton } from "react-native-paper";
import { useRoute } from "@react-navigation/native";
import Moment from "moment";
import useSWR from "swr";

import { SavedOffer } from "../../types";
import { fetcher } from "../../utilities/fetcher";
import { deleteItem } from "../../utilities/deleteItem";
import { track } from "../../utilities/Analytics";
import { AuthContext } from "../../AuthContext";
import { DeleteItemModal } from "../DeleteItemModal";

interface Props {
  savedOffer: SavedOffer;
  itemKey: string;
  isUserCoupon: boolean;
}

export function SavedOfferCard({ savedOffer, itemKey, isUserCoupon }: Props) {
  const [modalVisible, setModalVisible] = useState(false);
  const { data } = useSWR("getCoupons", fetcher);
  const imageUri = getFirstImage(savedOffer);

  const user = useContext(AuthContext);
  const route = useRoute();

  const hasDiscount = isUserCoupon && savedOffer.coupon.discount !== "";
  const name =
    savedOffer.name.charAt(0).toUpperCase() + savedOffer.name.slice(1);

  let date = savedOffer.coupon.expiry ? savedOffer.coupon.expiry : null;
  if (date && date.length > 0) {
    date = Moment(date).format("DD/MM/YY");
  }

  const showModal = () => setModalVisible(true);

  const hideModal = () => setModalVisible(false);

  const handleDelete = async () => {
    hideModal();
    deleteItem(itemKey, data);
    track(`${route.name}.DeleteItem`, user.uid);
  };

  const imgSource = () => {
    if (imageUri) {
      return { uri: imageUri };
    }
    return require("../../assets/images/default-coupon.png");
  };

  return (
    <Surface key={itemKey} style={styles.surface}>
      <DeleteItemModal
        visible={modalVisible}
        hideModal={hideModal}
        onDelete={handleDelete}
      />
      <View style={styles.cardContainer}>
        <IconButton
          icon="close-circle"
          color="#C76033"
          size={18}
          onPress={showModal}
          style={styles.iconButton}
          accessibilityLabel='Delete coupon'
        />
        <View style={styles.itemInfo}>
          <Image
            style={styles.itemImage}
            source={imgSource()}
            resizeMode="contain"
          />
          <Text numberOfLines={2} style={styles.title}>
            {name}
          </Text>
          {hasDiscount && (
            <Text style={styles.savings}>
              ${savedOffer.coupon.discount} OFF
            </Text>
          )}
        </View>
        <View>
          <Text style={styles.expiry}>{date && `EXPIRES ${date}`}</Text>
        </View>
      </View>
    </Surface>
  );
}

function getFirstImage(item) {
  const { cashbackOffers, flyerItems, onlineCoupons } = item;

  const firstImage =
    cashbackOffers.find((cashbackOffer) => cashbackOffer.image_url) ||
    flyerItems.find((flyerItem) => flyerItem.clipping_image_url) ||
    onlineCoupons.find((onlineCoupon) => onlineCoupon.image_url);

  return firstImage
    ? firstImage.image_url || firstImage.clipping_image_url
    : null;
}

const styles = StyleSheet.create({
  surface: {
    height: 192,
    elevation: 5,
    margin: 7,
    borderRadius: 4,
    padding: 6,
    marginVertical: 10,
  },
  iconButton: {
    margin: 0,
    alignSelf: "flex-end",
  },
  title: {
    fontSize: 12,
    marginTop: 8,
    fontFamily: "montserrat-regular",
    textAlign: 'center',
  },
  itemImage: {
    width: 100,
    height: 80,
    marginTop: -10
  },
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  itemInfo: {
    alignItems: "center",
  },
  expiry: {
    fontWeight: "500",
    fontSize: 10,
    color: "#979797",
    textAlign: "left",
    marginBottom: 2,
  },
  savings: {
    fontSize: 16,
    fontFamily: "gotham-bold",
    marginBottom: 5,
    color: "#A8CF92",
  },
});
