import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { View, Platform } from "react-native";
import { Avatar, Title, Paragraph, Button } from "react-native-paper";

import { RootStackParamList } from "../../../types";
import welcomeMessage from "./Welcome.json";

type WelcomeScreenProp = StackNavigationProp<
  RootStackParamList,
  "WelcomeScreen"
>;

interface Props {
  navigation: WelcomeScreenProp;
}

export function WelcomeScreen({ navigation }: Props) {
  return (
    <View style={{ padding: 52, flex: 1, justifyContent: "center" }}>
      <Avatar.Image size={80} source={require("../assets/savin-logo.png")} />
      <Title
        style={{
          fontSize: 32,
          fontFamily: "gotham-bold",
          paddingBottom: 16,
          paddingTop: 32,
        }}
      >
        Welcome to Savin Groceries!
      </Title>
      <Paragraph
        style={{
          fontFamily: "gotham-light",
          fontSize: 16,
        }}
      >
        {welcomeMessage.welcome}
      </Paragraph>
      <Button
        mode="contained"
        compact
        style={{ width: "80%", marginTop: 50, alignSelf: "center" }}
        color="#A8CF92"
        labelStyle={{ color: "white" }}
        onPress={() => navigation.navigate("OnboardingWalkthrough")}
      >
        Got It!
      </Button>
    </View>
  );
}
