import React from "react";
import { StyleSheet } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { IconButton } from "react-native-paper";

import { CouponsParamList, SavedTabsParamList } from "../../../types";
import { FavouritedScreen } from "../../../screens/CouponsScreen/screens/FavouritedScreen/FavouritedScreen";
import { MyCouponsScreen } from "../../../screens/CouponsScreen/screens/MyCouponsScreen/MyCouponsScreen";
import { SavedItemMatchesScreen } from "../../../screens/SavedItemMatchesScreen";

const CouponsStack = createStackNavigator<CouponsParamList>();
const TopTab = createMaterialTopTabNavigator<SavedTabsParamList>();

// Saving in case we reimpelemnt this design
function SavedTabs() {
  return (
    <TopTab.Navigator
      initialRouteName="Favourited"
      tabBarOptions={{
        style: { backgroundColor: "#A8CF92" },
        indicatorStyle: { backgroundColor: "white" },
        labelStyle: {
          fontSize: 14,
          fontFamily: "montserrat-medium",
          color: "white",
        },
      }}
    >
      <TopTab.Screen name="Favourited" component={FavouritedScreen} />
      <TopTab.Screen
        name="MyCoupons"
        component={MyCouponsScreen}
        options={{ title: "My Coupons" }}
      />
    </TopTab.Navigator>
  );
}

export function CouponsNavigator() {
  const navigation = useNavigation();

  return (
    <CouponsStack.Navigator>
      <CouponsStack.Screen
        name="Coupons"
        component={MyCouponsScreen}
        options={{
          headerTitle: "My Coupons",
          headerStyle: { elevation: 0 },
          headerRight: () => (
            <IconButton
              icon="plus"
              color="black"
              size={30}
              onPress={() => navigation.navigate("UserCouponForm", {
                couponToUpdate: null,
                couponId: null,
              })}
              rippleColor="#C4C4C4"
              accessibilityLabel='Add physical coupon'
            />
          ),
          headerTitleStyle: { fontFamily: "gotham-bold", fontSize: 22 },
        }}
      />
      <CouponsStack.Screen
        name="SavedItemMatches"
        component={SavedItemMatchesScreen}
        options={() => ({
          title: null
        })}
      />
    </CouponsStack.Navigator>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "white",
    elevation: 0,
    height: 90,
  },
});
