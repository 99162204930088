import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { Text } from "react-native-paper";

import { SavedOfferEntry } from "../../../../types";
import { EmptyState } from "../../../../components/EmptyState";
import { CouponsScreenNavigationProp } from "../../types";
import { CouponListItem } from "../CouponListItem";

interface Props {
  hasSearchQuery: boolean;
  savedOffersList: SavedOfferEntry[];
  navigation: CouponsScreenNavigationProp;
  isUserCoupon: boolean;
}

export function SavedList({
  hasSearchQuery,
  savedOffersList,
  navigation,
  isUserCoupon,
}: Props) {
  return (
    <FlatList
      numColumns={2}
      contentContainerStyle={styles.flatList}
      data={savedOffersList}
      renderItem={({ item }) => (
        <CouponListItem
          savedOfferEntry={item}
          navigation={navigation}
          isUserCoupon={isUserCoupon}
        />
      )}
      ListEmptyComponent={
        !hasSearchQuery ? (
          <EmptyState
            icon="card-bulleted"
            emptyStateMessage="You haven't added any coupons yet!"
          />
        ) : (
          <Text>No search results</Text>
        )
      }
      keyExtractor={(item) => item[0]}
      horizontal={false}
    />
  );
}

const styles = StyleSheet.create({
  flatList: {
    paddingHorizontal: 20,
    marginTop: 16,
  },
});
