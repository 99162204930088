import React, { useState, useContext } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  Button,
  List,
  Portal,
  TextInput,
  Snackbar,
  Dialog,
  Paragraph,
} from "react-native-paper";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import useSWR, { mutate } from "swr";
import * as WebBrowser from "expo-web-browser";

import { firebase } from "../../firebaseConfig";
import { AuthContext } from "../../AuthContext";
import { RootStackParamList } from "../../types";
import { postalCodeFetcher } from "../../utilities/fetcher";
import { track } from "../../utilities/Analytics";
import { cleanPostalCode, isValidPostalCode } from '../../utilities/formUtils';

type ProfileScreenRouteProp = RouteProp<RootStackParamList, "Profile">;
type ProfileScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Profile"
>;

interface Props {
  route: ProfileScreenRouteProp;
  navigation: ProfileScreenNavigationProp;
};

const FEEDBACK_FORM_LINK = 'https://forms.gle/n4TvLZsCtxRnEcUG7';

function handleOpenFeedbackLink() {
  WebBrowser.openBrowserAsync(FEEDBACK_FORM_LINK);
}

export function ProfileScreen({ route, navigation }: Props) {
  const { data: currentPostalCode } = useSWR(
    "getPostalCode",
    postalCodeFetcher
  );

  const showModal = (route.params && route.params.modalOpen) || false;
  const [modalVisible, setModalVisible] = useState(showModal);
  const [snackVisible, setSnackVisible] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const user = useContext(AuthContext);

  const cleanedPostalCode = cleanPostalCode(postalCode);

  const openModal = () => {
    setSnackVisible(false);
    setPostalCode("");
    setModalVisible(true);
  };

  const hideModal = () => setModalVisible(false);

  const onChange = (value: string) => {
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
    setPostalCode(value);
  };

  const handleApply = async () => {
    if (!isValidPostalCode(cleanedPostalCode)) {
      setShowErrorMessage(true);
      return;
    }

    hideModal();
    setSnackVisible(true);
    track(`${route.name}.ChangePostalCode`, user.uid);

    const cloudFunction = firebase
      .functions()
      .httpsCallable("updatePostalCode");
    await cloudFunction({ postalCode: cleanedPostalCode }).then((result) => {
      console.log(result);
      mutate("getMatches", null, false);
      mutate("getCoupons");
    });
    mutate("getMatches");
  };

  return (
    <View style={styles.container}>
      <View style={{ marginBottom: 10 }}>
        {user && <Text style={styles.title}>{user.email}</Text>}
      </View>
      <List.Item
        title="Change Location"
        description="Details shown are based on your location"
        left={(props) => <List.Icon {...props} icon="map-marker-outline" />}
        onPress={openModal}
      />
      <List.Item
        title="Tutorial"
        left={(props) => <List.Icon {...props} icon="help-circle-outline" />}
        onPress={() => {
          navigation.navigate("OnboardingWalkthrough");
        }}
      />
      <List.Item
        title="Feedback"
        left={(props) => <List.Icon {...props} icon="message-outline" />}
        onPress={() => {
          handleOpenFeedbackLink();
        }}
      />
      <List.Item
        title="Sign out"
        left={(props) => <List.Icon {...props} icon="exit-to-app" />}
        onPress={() => {
          firebase.auth().signOut();
        }}
      />
      <Snackbar
        duration={2500}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
      >
        Postal code updated to {cleanedPostalCode}
      </Snackbar>
      <Portal>
        <Dialog visible={modalVisible} onDismiss={hideModal}>
          <Dialog.Title>Change Location</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Find the latest deals at stores near you</Paragraph>
            <Paragraph>Your current location is {currentPostalCode}.</Paragraph>
            <TextInput
              style={{ marginVertical: 20 }}
              label="Postal Code"
              value={cleanedPostalCode}
              onChangeText={onChange}
            />
            {showErrorMessage && <Text>Invalid postal code format</Text>}
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideModal}>Cancel</Button>
            <Button onPress={handleApply}>Apply</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    backgroundColor: "white",
  },
  title: {
    fontSize: 16,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },

  modal: {
    backgroundColor: "white",
    padding: 30,
    paddingBottom: 40,
    margin: 20,
  },
  modalContent: {
    height: 230,
  },
  buttonGroup: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  modalButton: {
    width: "45%",
  },
  button: {
    alignSelf: "flex-end",
  },
});
