import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { IconButton, Divider } from "react-native-paper";

import { EmptyState } from "../EmptyState";
import { Offer, SavedOffer, ItemType } from "../../types";
import { MutationData } from "../types";

import { MatchesHorizontalList } from "./components/MatchesHorizontalList";

interface Props {
  item: Offer | SavedOffer;
  onManageSaveState: ({ itemId, itemTypes, itemIndex }: MutationData) => void;
  onIconPress: () => void;
  userCoupon?: JSX.Element;
  offerIsFavourited?: boolean;
  offerIsSearchResult?: boolean;
}

export function ItemMatches({
  item,
  userCoupon,
  offerIsFavourited = false,
  offerIsSearchResult = false,
  onManageSaveState,
  onIconPress,
}: Props) {
  const saved = offerIsFavourited || item.saved;
  const headerIcon = () => {
    if (!userCoupon && !saved) {
      return "cart-plus"
    }
    return "delete"
  };
  const showEmptyState =
    !userCoupon &&
    item.cashbackOffers.length === 0 &&
    item.flyerItems.length === 0 &&
    item.onlineCoupons.length === 0;

  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.title}>
          {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
        </Text>
        {!offerIsSearchResult && (
          <IconButton
            icon={headerIcon()}
            color={saved ? "#C76033" : "#D6D6D6"}
            onPress={() => onIconPress()}
            style={{ justifyContent: "flex-start" }}
            accessibilityLabel={headerIcon() === 'cart-plus' ? 'Add deal to your list' : 'Remove deal from your list'}
          />
        )}
      </View>
      <Divider />
      {showEmptyState ? (
        <EmptyState
          icon="cart"
          emptyStateMessage="There are currently no offers available for this item."
        />
      ) : (
        <>
          {userCoupon}

          {item.cashbackOffers.length > 0 ? (
            <>
              <MatchesHorizontalList
                listData={item.cashbackOffers}
                onManageSaveState={onManageSaveState}
                listHeading="Cashback Offers"
                itemTypes={{
                  listName: "cashbackOffers",
                  type: ItemType.CashbackItem,
                }}
              />
              <Divider />
            </>
          ) : null}

          {item.onlineCoupons.length > 0 ? (
            <>
              <MatchesHorizontalList
                listData={item.onlineCoupons}
                onManageSaveState={onManageSaveState}
                listHeading="Online Coupons"
                itemTypes={{
                  listName: "onlineCoupons",
                  type: ItemType.Coupon,
                }}
              />
              <Divider />
            </>
          ) : null}

          {item.flyerItems.length > 0 ? (
            <>
              <MatchesHorizontalList
                listData={item.flyerItems}
                onManageSaveState={onManageSaveState}
                listHeading="Flyer Deals"
                itemTypes={{
                  listName: "flyerItems",
                  type: ItemType.FlyerItem,
                }}
              />
            </>
          ) : null}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 12
  },
  title: {
    fontSize: 22,
    fontFamily: "gotham-bold",
    marginTop: 6,
  },
});
