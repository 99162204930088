import { firebase } from "../firebaseConfig";

export const fetcher = async (functionName: string) => {
  const cloudFunction = firebase.functions().httpsCallable(functionName);
  return await cloudFunction().then((result) => {
    return result?.data;
  });
};

export const searchFetcher = async (
  functionName: string,
  searchQuery: string
) => {
  const cloudFunction = firebase.functions().httpsCallable(functionName);
  return await cloudFunction({ searchQuery }).then((result) => {
    return result?.data;
  });
};

export const postalCodeFetcher = async () => {
  const cloudFunction = firebase.functions().httpsCallable("getPostalCode");
  return await cloudFunction().then((result) => {
    return result.data;
  });
};
