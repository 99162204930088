import firebase from "firebase";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCblTpW6UluoUPzK8JaZYvHm8V4T2N7kEQ",
  authDomain: "stackr-backend.firebaseapp.com",
  databaseURL: "https://stackr-backend.firebaseio.com",
  projectId: "stackr-backend",
  storageBucket: "stackr-backend.appspot.com",
  messagingSenderId: "243685803959",
  appId: "1:243685803959:web:dbc3b8d283a7eefa714a32",
  measurementId: "G-PZ9KW4SV30",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
