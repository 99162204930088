import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { Surface, IconButton } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";

export interface UserCouponDetails {
  product: string;
  notes?: string;
  discount?: string;
  expiry?: string;
}

interface Props {
  couponDetails: UserCouponDetails;
  couponId: string;
}

export function UserCoupon({ couponDetails, couponId }: Props) {
  const navigation = useNavigation();
  const { product, discount, expiry, notes } = couponDetails;

  return (
    <View>
      <Text style={styles.preHeading}>YOUR COUPON</Text>
      <Surface style={styles.surface}>
        <View style={styles.itemCardContainer}>
          <IconButton
            icon={"pencil"}
            color={"#C76033"}
            size={18}
            onPress={() =>
              navigation.navigate("UserCouponForm", {
                couponToUpdate: couponDetails,
                couponId,
              })
            }
            style={styles.icon}
            accessibilityLabel='Edit coupon'
          />
          <View style={styles.itemInfo}>
            <Image
              style={styles.itemImage}
              source={require("../../assets/images/default-coupon.png")}
              resizeMode="contain"
            />
            <Text numberOfLines={1} style={styles.title}>
              {product}
            </Text>
            {!!discount && <Text style={styles.savings}>${discount} OFF</Text>}
            {!!notes && (
              <Text numberOfLines={3} style={styles.description}>
                <Text style={styles.noteTitle}>Notes:</Text> {notes}
              </Text>
            )}
          </View>
          <View>
            {!!expiry && <Text style={styles.expiry}>EXPIRES {expiry}</Text>}
          </View>
        </View>
      </Surface>
    </View>
  );
}
const styles = StyleSheet.create({
  surface: {
    minHeight: 192,
    elevation: 5,
    margin: 7,
    borderRadius: 4,
    padding: 6,
    width: 152,
  },

  title: {
    fontSize: 12,
    marginTop: 8,
    textAlign: "center",
    fontFamily: "montserrat-regular",
    color: "black",
    marginBottom: 6,
  },
  itemImage: {
    width: 40,
    height: 40,
  },
  itemCardContainer: {
    flex: 1,
  },
  itemInfo: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  expiry: {
    fontFamily: "montserrat-regular",
    fontSize: 10,
    color: "#979797",
    textAlign: "left",
    marginBottom: 2,
  },
  preHeading: {
    color: "black",
    fontSize: 14,
    letterSpacing: 1,
    marginTop: 10,
    fontFamily: "montserrat-medium",
  },
  productName: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
  },
  noteTitle: {
    fontFamily: "montserrat-medium",
  },
  description: {
    fontSize: 10,
    margin: 2,
    textAlign: "center",
    fontFamily: "montserrat-regular",
  },
  savings: {
    fontSize: 16,
    fontFamily: "gotham-bold",
    marginBottom: 5,
    color: "#A8CF92",
  },
  merchantLogo: {
    width: 40,
    height: 40,
  },
  icon: {
    margin: 0,
    marginBottom: -10,
    alignSelf: "flex-end",
  },
});
