export enum ErrorType {
  EmailAddress,
  Password,
  PostalCode,
  Empty
}

export type Error = {
  type: ErrorType;
  message: string;
};
