import React from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList, SavedOfferEntry } from "../../../../types";
import { SavedOfferCard } from "../../../../components/SavedOfferCard";

type CouponsScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Favourites"
>;

interface Props {
  savedOfferEntry: SavedOfferEntry;
  navigation: CouponsScreenNavigationProp;
  isUserCoupon: boolean;
}

export function CouponListItem({
  savedOfferEntry,
  navigation,
  isUserCoupon,
}: Props) {
  const [key, savedOffer] = savedOfferEntry;

  return (
    <View key={key} style={styles.couponListItem}>
      <Pressable
        onPress={() =>
          navigation.navigate("SavedItemMatches", {
            key,
          })
        }
      >
        <SavedOfferCard
          savedOffer={savedOffer}
          itemKey={key}
          isUserCoupon={isUserCoupon}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  couponListItem: {
    flex: 1,
    flexDirection: "column",
    maxWidth: "50%",
  },
});
