import React, { useState, useEffect, useRef } from "react";
import { Platform } from "react-native";
import {
  NavigationContainer,
  DefaultTheme,
  NavigationContainerRef,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useMediaQuery } from "react-responsive";
import Constants from "expo-constants";

import { firebase } from "../firebaseConfig";
import { NotFoundScreen } from "../screens/NotFoundScreen";
import { SplashScreen } from "../screens/SplashScreen";
import { RootStackParamList } from "../types";
import { AuthNavigator } from "./AuthNavigator";
import { MainNavigator } from "./MainNavigator/MainNavigator";
import { AuthContext } from "../AuthContext";
import { UserCouponForm } from "../screens/UserCouponForm";
import { OnboardingWalkthrough } from "../screens/OnboardingWalkthrough";
import { WelcomeScreen } from "../screens/auth/WelcomeScreen";
import { WebInstallScreen } from "../screens/WebInstallScreen";
import { track } from "../utilities/Analytics";

export function Navigation() {
  const navigationRef = useRef<NavigationContainerRef>(null);
  const routeNameRef = useRef<NavigationContainerRef>(null);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isIos, setIsIos] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  const Stack = createStackNavigator<RootStackParamList>();

  const isStandalone = useMediaQuery({ query: "(display-mode: standalone)" });

  Constants.getWebViewUserAgentAsync().then((agent) => {
    if (agent) {
      let cleanedAgent = agent.toLowerCase();
      setIsIos(cleanedAgent.includes("iphone") || cleanedAgent.includes("ipad"));
      setIsAndroid(cleanedAgent.includes("android"));
    }
  });

  const isWebDesktop = Platform.OS === 'web' && !isStandalone;
  const showWebInstallScreen = isIos && !isStandalone && Platform.OS === "web";
  const showAndroidWebInstallScreen = isAndroid && !isStandalone && Platform.OS === 'web';

  useEffect(() => {
    const authSubscriber = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setIsLoading(false);
    });

    return authSubscriber;
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (showWebInstallScreen || showAndroidWebInstallScreen) {
    return <WebInstallScreen os={isIos ? 'ios' : 'android'} />;
  }

  return (
    <NavigationContainer
      theme={DefaultTheme}
      ref={navigationRef}
      onReady={() =>
        // @ts-ignore
        (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
      }
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        // @ts-ignore
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        // @ts-ignore
        if (previousRouteName !== currentRouteName) {
          await track(currentRouteName + "Screen.open", user?.uid);
        }

        // @ts-ignore
        routeNameRef.current = currentRouteName;
      }}
    >
      <AuthContext.Provider value={user}>
        <Stack.Navigator screenOptions={{
          headerShown: false, cardStyle: isWebDesktop && {
            width: 800,
            alignSelf: 'center',
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 3,
            },
            shadowOpacity: 0.27,
            shadowRadius: 4.65,
            elevation: 6,
            marginVertical: 10,
            borderRadius: 5
          }
        }} mode="modal">
          {!user ? (
            <>
              <Stack.Screen name="AuthNavigation" component={AuthNavigator} />
            </>
          ) : (
            <>
              <Stack.Screen name="Root" component={MainNavigator} />
              <Stack.Screen name="UserCouponForm" component={UserCouponForm} />
            </>
          )}
          <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} />
          <Stack.Screen
            name="OnboardingWalkthrough"
            component={OnboardingWalkthrough}
          />
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: "Oops!" }}
          />
        </Stack.Navigator>
      </AuthContext.Provider>
    </NavigationContainer>
  );
}
