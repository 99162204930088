import React from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { Title, Text } from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { StackNavigationProp } from "@react-navigation/stack";
import useSWR from "swr";

import { postalCodeFetcher } from "../../../../utilities/fetcher";
import { RootStackParamList } from "../../../../types";

interface Props {
  navigation: StackNavigationProp<RootStackParamList, "Root">;
};

export function HomeScreenHeader({ navigation }: Props) {
  const { data: postalCode } = useSWR("getPostalCode", postalCodeFetcher);

  const postalCodeText =
    postalCode && typeof postalCode === "string" ? (
      <Text style={{ fontSize: 12, marginTop: 12 }}>({postalCode})</Text>
    ) : null;

  return (
    <View style={styles.headingContainer}>
      <View style={styles.headingRow}>
        <Title style={{ fontSize: 22 }}>Featured Deals</Title>
        <Pressable
          onPress={() => navigation.navigate("Profile", { modalOpen: true })}
          style={styles.headingRow}
        >
          <MaterialCommunityIcons
            name="map-marker-outline"
            size={24}
            color="black"
          />
          {postalCodeText}
        </Pressable>
      </View>
      <Text>Deals you don't want to miss!</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  headingContainer: {
    paddingBottom: 25,
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  headingRow: {
    flexDirection: "row",
  },
});
