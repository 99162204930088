import { SavedOfferMap, SavedOfferEntry } from "../../types";

export function buildSavedItemsCoupons(savedOffers?: SavedOfferMap) {
  let tripOffers: SavedOfferEntry[] = [];

  Object.entries(savedOffers || {}).map((entry) => {
    if (entry[1].name) {
      tripOffers.push(entry);
    }
  });
  return tripOffers;
}
