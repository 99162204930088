import React from "react";
import { StyleSheet } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";

import { SearchParamList } from "../../../types";
import { SearchScreen } from "../../../screens/SearchScreen";
import { SearchItemMatchesScreen } from "../../../screens/SearchItemMatchesScreen";

const SearchStack = createStackNavigator<SearchParamList>();

export function SearchNavigator() {
  return (
    <SearchStack.Navigator>
      <SearchStack.Screen
        name="Search"
        component={SearchScreen}
        options={{
          headerTitle: "Search",
          headerStyle: { elevation: 0 },
          headerTitleStyle: { fontFamily: "gotham-bold", fontSize: 22 },
        }}
      />
      <SearchStack.Screen
        name="SearchItemMatches"
        component={SearchItemMatchesScreen}
        options={() => ({
          title: null
        })}
      />
    </SearchStack.Navigator>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "white",
    elevation: 0,
    height: 90,
  },
});
