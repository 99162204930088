import React, { useState } from "react";
import { StyleSheet, View, SafeAreaView } from "react-native";
import { Title, Text, Snackbar, Avatar } from "react-native-paper";

import { firebase } from "../../../firebaseConfig";
import { SignInScreenProps } from "../../../types";

import { SignInForm } from "./components/SignInForm";

export function SignInScreen({ navigation }: SignInScreenProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState(false);

  const onLoginSuccess = () => {
    console.log("logged in!");
  };

  const onLoginFailure = (errorMessage: string) => {
    setErrorMessage(errorMessage);
  };

  const signInWithEmail = async (email: string, password: string) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(onLoginSuccess)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode == "auth/weak-password") {
          onLoginFailure("Weak Password!");
        } else {
          onLoginFailure(errorMessage);
        }
      });
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);

    setSnackVisible(true);
  };

  return (
    <SafeAreaView style={styles.container}>
      <Avatar.Image size={80} source={require("../assets/savin-logo.png")} />
      <Title style={styles.titleText}>Sign in to Savin Groceries</Title>
      <SignInForm
        handleSubmit={signInWithEmail}
        handleReset={resetPassword}
        errorMessage={errorMessage}
      />
      <View style={styles.signUpButton}>
        <Text
          onPress={() => {
            navigation.navigate("SignUp");
          }}
        >
          Don't have an account?
        </Text>
      </View>
      <Snackbar
        duration={7000}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
      >
        Check your email to reset your password.
      </Snackbar>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    fontSize: 34,
    paddingVertical: 10,
    width: "80%",
    marginTop: 20,
  },
  signUpButton: {
    marginTop: 10,
  },
});
