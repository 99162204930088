import * as Analytics from 'expo-firebase-analytics';

let isFirebaseInitialized = false;

async function initialize(uid) {
  if (!uid) {
    return;
  }

  Analytics.setUserId(uid);
  isFirebaseInitialized = true;
}

export async function track(
  event: string,
  uid: string | undefined,
  options?: object
) {
  await initialize(uid);

  if (!isFirebaseInitialized) {
    return;
  }

  if (options) {
    console.log(
      `Analytics.track uid=${uid}, event=${event}, options=`,
      options
    );
    Analytics.logEvent(event, options);
  } else {
    console.log(`Analytics.track uid=${uid}, event=${event}`);
    Analytics.logEvent(event)
  }
}
