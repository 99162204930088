import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList } from "../../../../types";
import { CouponsScreen } from "../../CouponsScreen";

export type CouponsScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Favourites"
>;

interface Props {
  navigation: CouponsScreenNavigationProp;
}

export function MyCouponsScreen({ navigation }: Props) {
  return <CouponsScreen navigation={navigation} filteredByCoupons={true} />;
}
